import React, { useEffect, useState } from 'react';
import aboutImg from '../../../assets/img/about_us_main.webp';
import heroMainSmall from '../../../assets/img/hero_main_small.webp';
import heroMainMedium from '../../../assets/img/hero_main_medium.webp';
import heroMainLarge from '../../../assets/img/hero_main_large.webp';
import videoSource from '../../../assets/img/how-it-works-xolani-bridge-v2.mp4';
import videoHolder from '../../../assets/img/Xolani_Video_Interaction.webp';
import { Link } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import './style.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import FAQ from './components/FAQ';
import OurServices from './components/OurServices';
import { landingConstants } from './components/Why/constant';
import Why from './components/Why';
import XBButton from '../../../components/XBButton';
import Navbar from './components/Navbar';
import { MdPlayArrow } from 'react-icons/md';
import FullScreenVideoModal from '../../../components/FullScreenVideoModal/FullScreenVideoModal';
import Featured from './components/Featured';

function HomePage() {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleVideoClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  function onClose() {
    var menuToggle = document.querySelector('.nav-open');
    var navigation = document.querySelector('.nav-content');
    menuToggle.classList.remove('active');
    navigation.classList.remove('active');
  }

  return (
    <div className="bg-[#EBF6F8]">
      <Navbar />
      <div id="home" className="m-0 p-0 sm:mt-8">
        <section
          style={{
            background: 'linear-gradient(180deg, #CAEAEE 0%, #FAFFFF 53.2%, #EAF3F4 106.39%)',
          }}
          className="flex flex-col justify-center  pt-20 pb-10 sm:py-10 md:py-14 sm:px-0 md:px-5 lg:px-[20px] xl:px-[50px] bg-white w-full"
        >
          <div className="max-w-[1280px] w-full mx-auto  flex gap-14 lg:flex-col">
            <div className="p-4 flex-1  max-w-[610px] sm:w-full md:w-full flex flex-col justify-center">
              <h1 className="font-semibold text-[40px] sm:text-3xl  text-[#011E21] text-left mb-4 whitespace-nowrap xl:whitespace-normal">
                Revolutionize your radiology <br className="xl:hidden md:block" /> practice with our advanced{' '}
                <br className="xl:hidden md:block" />
                <span className="text-[#068999]">tele-radiology</span> platform{' '}
              </h1>

              <p className="font-semibold text-[#828A8B] text-xl sm:text-lg md:text-lg lg:text-[18px] text-left mb-10 leading-9">
                We offer a wide range of benefits that enhance collaboration, streamline workflows, and improve patient
                care. Join XolaniBridge today and enter a new era of radiology excellence.
              </p>

              <Link to="services" smooth={true} duration={500} spy={true} onClick={() => onClose()}>
                <XBButton>Get Started</XBButton>
              </Link>
            </div>
            <div className="relative p-10 flex-1 w-full sm:w-full md:w-full overflow-hidden flex items-center justify-end">
              <img
                className="w-full max-w-[500px] max-h-[550px] sm:max-h-[400px] object-cover object-bottom rounded-tr-[60px] rounded-bl-[60px]"
                src={heroMainLarge}
                srcSet={`${heroMainSmall} 320w, ${heroMainMedium} 600w, ${heroMainLarge} 1080w`}
                sizes="(max-width: 600px) 100vw, (max-width: 1024px) 50vw, 500px"
                alt="hero"
              />

              <div className="absolute top-1/2 transform -translate-y-1/2 sm:pl-4 ">
                <img className="w-full max-w-[550px] rounded-[10px] object-cover" src={videoHolder} alt="videoHolder" />
                <div className="absolute w-full h-full bg-[#f5ffff] top-0 left-0 rounded-[10px] opacity-40"></div>
                <div
                  onClick={handleVideoClick}
                  className="h-[75px] w-[75px] bg-white rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center text-[#18181B] shadow-2xl cursor-pointer"
                >
                  <MdPlayArrow size={22} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <FullScreenVideoModal isOpen={isModalOpen} onClose={handleCloseModal} videoSrc={videoSource} />

        {/* Featured */}
        <section className="px-6 bg-[#FFFFFF]">
          <Featured />
        </section>

        {/* About us*/}
        <section
          id="about"
          className="flex flex-col justify-center  py-20 sm:py-10 md:py-14 sm:px-0 md:px-5 lg:px-[20px] xl:px-[50px] bg-white w-full"
        >
          <div className="w-full max-w-[1280px] mx-auto  flex  justify-between md:flex-col ">
            <div className="p-4 w-1/2 sm:w-full md:w-full overflow-hidden flex items-center">
              <img
                className="w-full max-h-[460px] max-w-[520px] object-fill"
                src={aboutImg}
                alt="about"
                loading="lazy"
              />
            </div>
            <div className="p-10  l g:p-4  md:p-8 w-1/2 max-w-[600px] sm:w-full md:w-full flex flex-col justify-center ">
              <h1 className="font-semibold text-4xl sm:text-2xl lg:text-[28px] text-[#596061] text-left mb-4">
                About XolaniBridge
              </h1>
              <h2 className="font-medium text-xl sm:text-lg lg:text-[18px] text-[#068999] text-left mb-8 ">
                Making Healthcare Accessible, For All, Together
              </h2>
              <p className="font-medium text-[#828A8B] text-xl sm:text-lg md:text-lg lg:text-[18px] text-left leading-9">
                At XolaniBridge, we're passionate about revolutionizing radiology. Our innovative platform is built to
                integrate seamlessly into your clinical workflow, empowering your team to collaborate in real-time and
                access world-class radiologists at a moment's notice. We're dedicated to making a difference in your
                daily work and, ultimately, in the lives of your patients.
              </p>
            </div>
          </div>
        </section>

        {/* Why Xolani Bridge */}
        <section id="features" className="px-6 bg-[#F5FFFF]">
          <Why benefitList={landingConstants} />
        </section>
        {/* Our Services */}
        <section id="services" className="px-6 bg-[#F5FFFF]">
          <OurServices />
        </section>

        {/* FAQs */}
        <section id="faqs" className="px-6 bg-[#FDFDFD]">
          <FAQ />
        </section>

        {/* Contact us */}
        <section
          id="contact-us"
          className="px-6 "
          style={{ background: 'linear-gradient(180deg, #FBFBFB 0%, #FAFFFF 53.2%, #EAF3F4 106.39%)' }}
        >
          <ContactUs />
        </section>

        {/* Footer */}
        <section id="footer">
          <Footer />
        </section>
      </div>
    </div>
  );
}

export default HomePage;
