const apk = false;

const enVars = {
  environment: apk ? 'apk' : process.env.NODE_ENV, // values => development | production

  production: {
    API_URL: 'https://xolanihealth.cloud',
    // API_URL2: 'https://aws.xolanihealth.cloud/api/v1',
    API_URL2: 'https://server.xolanihealth.cloud/api/v1',
    //API_URL2: 'http://localhost:3005/api/v1',

    ORTHANC_BASE_URL: 'http://localhost:8042',
    ORTHANC_AUTH_USERNAME: 'xolaniOrthanc',
    ORTHANC_AUTH_PASSWORD: 'xolaniOrthancP@ss',
    API_URL3: 'https://aws.xolanihealth.cloud',

    // API_URL3: 'https://server.xolanihealth.cloud',
    ORTHANC_URL: 'https://orthanc.xolanihealth.cloud',
    // ORTHANC_URL: 'http://127.0.0.1:8042',
    VIEWER_URL: 'https://viewer.xolanibridge.com/viewer',
  },
  development: {
    API_URL: 'https://xolanihealth.cloud',
    // API_URL2: 'https://aws.xolanihealth.cloud/api/v1',
    API_URL2: 'https://server.xolanihealth.cloud/api/v1',

    // API_URL3: 'https://aws.xolanihealth.cloud',
    API_URL3: 'https://server.xolanihealth.cloud',
    // API_URL2: 'http://localhost:3005/api/v1',

    ORTHANC_URL: 'https://orthanc.xolanihealth.cloud',
    ORTHANC_BASE_URL: 'http://localhost:8042',
    ORTHANC_AUTH_USERNAME: 'xolaniOrthanc',
    ORTHANC_AUTH_PASSWORD: 'xolaniOrthancP@ss',

    // ORTHANC_URL: 'http://127.0.0.1:8042',
    VIEWER_URL: 'https://viewer.xolanibridge.com/viewer',
  },
};

const env = {
  ENVIRONMENT: enVars.development,
  ...(enVars.environment === 'production'
    ? process.env.REACT_APP_ENV === 'development'
      ? enVars.development
      : enVars.production
    : enVars.development),
};

export default env;
