import {
  SET_AFFILIATES,
  SET_BRANCHES,
  SET_BRANCH_ADMIN_USERS,
  SET_BRANCH_TEAM,
  SET_CLOSED_OFFER,
  SET_DOCTORS,
  SET_INTERPRETERS,
  SET_IN_HOUSE,
  SET_NOTIFICATIONS,
  SET_OFFERS,
  SET_OFFER_BIDS,
  SET_OFFER_CONTRACT,
  SET_PACKS,
  SET_RADIOGRAPHERS,
  SET_REGULARS,
  SET_TEAMS,
  SET_TEMPLATES,
  SET_WALLET_TRANSACTION,
} from './types';

const initialState = {
  offers: [],
  offerBids: [],
  wallets: [],
  radiographers: [],
  inHouse: [],
  templates: [],
  offerContracts: [],
  closedOffers: [],
  packs: [],
  doctors: [],
  interpreters: [],
  teams: [],
  regulars: [],
  branches: [],
  branchTeams: [],
  branchAdminUsers: [],
  affiliates: [],
  notifications: [],
};

const projectReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SET_OFFERS:
      return {
        ...state,
        offers: data,
      };
    case SET_OFFER_BIDS:
      return {
        ...state,
        offerBids: data,
      };
    case SET_OFFER_CONTRACT:
      return {
        ...state,
        offerContracts: data,
      };
    case SET_CLOSED_OFFER:
      return {
        ...state,
        closedOffers: data,
      };
    case SET_WALLET_TRANSACTION:
      return {
        ...state,
        wallets: data,
      };
    case SET_RADIOGRAPHERS:
      return {
        ...state,
        radiographers: data,
      };
    case SET_IN_HOUSE:
      return {
        ...state,
        inHouse: data,
      };
    case SET_TEMPLATES:
      return {
        ...state,
        templates: data,
      };
    case SET_PACKS:
      return {
        ...state,
        packs: data,
      };
    case SET_DOCTORS:
      return {
        ...state,
        doctors: data,
      };
    case SET_INTERPRETERS:
      return {
        ...state,
        interpreters: data,
      };
    case SET_TEAMS:
      return {
        ...state,
        teams: data,
      };
    case SET_REGULARS:
      return {
        ...state,
        regulars: data,
      };
    case SET_BRANCHES:
      return {
        ...state,
        branches: data,
      };
    case SET_BRANCH_TEAM:
      return {
        ...state,
        branchTeams: data,
      };
    case SET_BRANCH_ADMIN_USERS:
      return {
        ...state,
        branchAdminUsers: data,
      };
    case SET_AFFILIATES:
      return {
        ...state,
        affiliates: data,
      };

    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: data,
      };

    default:
      return state;
  }
};

export default projectReducer;
