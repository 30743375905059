import { message } from 'antd';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setCallingUser, setMessagingUser, setUnauthorizedMessages } from '../../../../redux/chats_messages/actions';
import {
  setActiveStream,
  setCurrentMeetingId,
  setIncomingCall,
  setLocalStream,
  setOnCall,
  setOutgoingCall,
} from '../../../../redux/globals/actions';
import peerService from '../../../../services/peer.service';
import socketService from '../../../../services/socket.service';

const useUnauthorizedCall = () => {
  const localStreamRef = useRef(null);
  const remoteStreamRef = useRef(null);
  const myAudioTrack = useRef(null);
  const myVideoTrack = useRef(null);
  const myScreenTrack = useRef(null);

  const { localStream, incomingCall, currentMeetingId, callType, myCallData } = useSelector((state) => state.globals);
  const { unauthorizedMessages } = useSelector((state) => state.chatsMessages);
  const history = useNavigate();
  const initialState = {
    audioMode: true,
    videoMode: true,
    screenMode: false,
    fullScreen: false,
    currentPage: 1,
    newMessages: 0,
  };
  const [state, dispatch] = useReducer((prevState, value) => ({ ...prevState, ...value }), initialState);

  const reduxDispatcher = useDispatch();
  const [usersOnCall, setUsersOnCall] = useState([]);
  const endCall = () => {
    myVideoTrack.current && myVideoTrack.current?.stop();
    myAudioTrack.current && myAudioTrack.current?.stop();
    myScreenTrack.current && myScreenTrack.current?.forEach((track) => track?.stop());
    reduxDispatcher(setOnCall(false));
    reduxDispatcher(setIncomingCall(null));
    reduxDispatcher(setOutgoingCall(null));
    reduxDispatcher(setMessagingUser(null));
    reduxDispatcher(setLocalStream(null));
    reduxDispatcher(setActiveStream(null));
    reduxDispatcher(setCurrentMeetingId(null));
    // reduxDispatcher(setUsersOnCall([]));
    setUsersOnCall([]);
    if (localStreamRef.current) {
      localStreamRef.current.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
      localStreamRef.current.srcObject = null;
    }

    socketService.emit('leave_unauthorized_call', {
      peerId: myCallData?.peerId,
      meetingId: currentMeetingId,
      userName: myCallData?.userName,
    });
    // history('/');
    window.location.reload(true);
  };

  useEffect(() => {
    socketService.on('unauthorized_call_message', (res) => {
      dispatch({ newMessages: state?.newMessages + 1 });
      reduxDispatcher(
        setUnauthorizedMessages([
          ...unauthorizedMessages,
          { text: res?.message, sender: res?.user, createdAt: new Date().toISOString() },
        ]),
      );
    });
  }, [unauthorizedMessages]);

  const getDeviceStream = (type = 'voice') => {
    const streamConfig = { video: true, audio: true };

    try {
      if (!navigator?.mediaDevices) {
        reduxDispatcher(setOnCall(false));
        message.info("Please ensure you have enabled your device's microphone and camera", [10]);
        return false;
      }
      navigator.mediaDevices.enumerateDevices().then((srcInfos) => {
        navigator.mediaDevices
          .getUserMedia(streamConfig)
          .then((deviceStream) => {
            deviceStream.getTracks().forEach((track) => {
              if (track.kind == 'audio') {
                track.enabled = state?.audioMode;
                myAudioTrack.current = track;
                dispatch({ audioMode: track.enabled });
              }

              if (track.kind == 'video') {
                if (type == 'voice') {
                  track.enabled = false;
                  dispatch({ videoMode: false });
                } else {
                  track.enabled = state?.videoMode;
                  dispatch({ videoMode: state?.videoMode });
                }
                myVideoTrack.current = track;
              }
            });

            reduxDispatcher(setLocalStream(deviceStream));
            reduxDispatcher(setActiveStream(deviceStream));
            localStreamRef.current.srcObject = deviceStream;
          })
          .catch((err) => {
            toast.error(err.message);
          });
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const toggleMic = () => {
    myAudioTrack.current.enabled = !myAudioTrack.current.enabled;
    dispatch({ audioMode: myAudioTrack.current.enabled });

    if (usersOnCall?.length && myAudioTrack.current) {
      usersOnCall?.forEach((user) => {
        // user.peer.replaceTrack(user.peer.streams[0].getAudioTracks()[0], myAudioTrack.current, user.peer.streams[0]);
        let sender = user.peerCall.peerConnection.getSenders().find((s) => s.track.kind == myAudioTrack.current.kind);

        sender.replaceTrack(myAudioTrack.current);
      });
    }
    socketService.emit('audioMode', {
      userName: myCallData?.userName,
      meetingId: currentMeetingId,
      audioMode: myAudioTrack.current.enabled,
      peerId: myCallData?.peerId,
    });
  };

  const toggleVideo = () => {
    myVideoTrack.current.enabled = !myVideoTrack.current.enabled;
    dispatch({ videoMode: myVideoTrack.current.enabled });
    if (usersOnCall?.length && myVideoTrack.current) {
      usersOnCall?.forEach((user) => {
        // user.peer.replaceTrack(user.peer.streams[0].getVideoTracks()[0], myVideoTrack.current, user.peer.streams[0]);
        let sender = user.peerCall.peerConnection.getSenders().find((s) => s.track.kind == myVideoTrack.current.kind);

        sender.replaceTrack(myVideoTrack.current);
      });
    }
    socketService.emit('videoMode', {
      userName: myCallData?.userName,
      meetingId: currentMeetingId,
      videoMode: myVideoTrack.current.enabled,
      peerId: myCallData?.peerId,
    });
  };

  const stopSharingScreen = () => {
    localStreamRef.current.srcObject = localStream;
    reduxDispatcher(setActiveStream(localStream));
    // const callPeer = incomingCall ? incomingCall.peerConnection : outgoingCall.peerConnection;
    // let sender = callPeer.getSenders().find((s) => s.track.kind == myScreenTrack.current[0].kind);
    // sender.replaceTrack(myVideoTrack.current);

    if (usersOnCall?.length) {
      usersOnCall?.forEach((user) => {
        // user.peer.replaceTrack(
        //   user.peer.streams[0].getVideoTracks()[0],
        //   localStream.getVideoTracks()[0],
        //   user.peer.streams[0],
        // );
        let sender = user.peerCall.peerConnection
          .getSenders()
          .find((s) => s.track.kind == myScreenTrack.current[0].kind);

        sender.replaceTrack(myVideoTrack.current);
      });
    }
    dispatch({ screenMode: false });
    myScreenTrack.current[0]?.stop();
  };

  const shareScreen = () => {
    // const callPeer = incomingCall ? incomingCall.peerConnection : outgoingCall.peerConnection;
    if (state.screenMode) {
      stopSharingScreen();
    } else {
      navigator.mediaDevices
        .getDisplayMedia({
          video: {
            cursor: 'always',
          },
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
          },
        })
        .then((stream) => {
          myScreenTrack.current = stream.getVideoTracks();
          dispatch({ screenMode: stream.getVideoTracks()[0].enabled });

          localStreamRef.current.srcObject = stream;
          reduxDispatcher(setActiveStream(stream));
          stream.getVideoTracks()[0].onended = () => stopSharingScreen();
          if (usersOnCall?.length) {
            usersOnCall?.forEach((user) => {
              // user.peer.replaceTrack(
              //   user.peer.streams[0].getVideoTracks()[0],
              //   stream.getVideoTracks()[0],
              //   user.peer.streams[0],
              // );
              let sender = user.peerCall.peerConnection
                .getSenders()
                .find((s) => s.track.kind == stream.getVideoTracks()[0].kind);

              sender.replaceTrack(stream.getVideoTracks()[0]);
            });
          }
          // reduxDispatcher(setLocalStream(stream));
          // let sender = callPeer.getSenders().find((s) => s.track.kind == stream.getVideoTracks()[0].kind);

          // sender.replaceTrack(stream.getVideoTracks()[0]);
        })
        .catch((err) => {});
    }
  };

  const maximizeRemoteVideo = (peerId) => {
    let videoElement = document.getElementById(`remote_user_${peerId}_video`);
    if (videoElement?.requestFullscreen) {
      videoElement?.requestFullscreen();
    } else if (videoElement?.msRequestFullscreen) {
      videoElement?.msRequestFullscreen();
    } else if (videoElement?.mozRequestFullScreen) {
      videoElement?.mozRequestFullScreen();
    } else if (videoElement?.webkitRequestFullscreen) {
      videoElement?.webkitRequestFullscreen();
    }
  };

  const onRejectCall = () => {
    incomingCall.close();
    reduxDispatcher(setOnCall(false));
    reduxDispatcher(setCalling(null));
    reduxDispatcher(setCallingUser(null));

    reduxDispatcher(setIncomingCall(null));
    reduxDispatcher(setCaller(false));
    reduxDispatcher(togglePopup(false));
    reduxDispatcher(setMessagingUser(null));
    reduxDispatcher(setCurrentMeetingId(null));
  };

  return {
    localStreamRef,
    getDeviceStream,
    toggleMic,
    toggleVideo,
    state,
    endCall,
    myVideoTrack,
    myAudioTrack,
    remoteStreamRef,
    shareScreen,
    stopSharingScreen,
    maximizeRemoteVideo,
    myScreenTrack,
    onRejectCall,
    usersOnCall,
    callType,
    myCallData,
    setUsersOnCall,
    dispatch,
  };
};

export default useUnauthorizedCall;
