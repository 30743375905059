import React from 'react';
import HeartIcon from '../../../../../assets/icons/heatbeat.svg';
import { Row, Col, Grid } from 'antd';
import WhyCard from './WhyCard';

const Why = ({ benefitList }) => {
  const screens = Grid.useBreakpoint();

  const itemsPerRow = screens.xxl ? 3 : screens.xl ? 3 : screens.lg ? 2 : screens.md ? 2 : 1; // Adjust according to breakpoints

  return (
    <div className="max-w-[1440px] mx-auto  py-20">
      <div className="flex flex-col items-center text-center gap-4 mb-8">
        <h3 className="text-[#596061] text-[2.5rem] font-semibold mb-0">Why Choose XolaniBridge</h3>
        <img src={HeartIcon} alt="heartbeat" loading="lazy" />
        <p className="text-[1.2rem] text-[#646C6D] font-medium">
          We empower you with seamless tele-radiology solutions, featuring advanced tools and capabilities.
        </p>
      </div>
      <div className="py-5 px-4 max-w-[1280px] mx-auto sm:px-5 md:px-5 xl:px-0  sm:w-full md:w-full gap-y-2">
        <Row justify={benefitList?.length === 4 ? 'center' : 'start'}>
          {benefitList?.map((item, index) => {
            const totalItems = benefitList.length;
            const isLastRow = index >= totalItems - (totalItems % itemsPerRow || itemsPerRow);
            const isLastColumn = (index + 1) % itemsPerRow === 0;
            const singleColumn = itemsPerRow < 2 ? true : false;
            const isCenter = totalItems === 4 ? true : false;
            return (
              <Col xs={24} sm={24} md={12} xl={8} xxl={8} key={item.title}>
                <WhyCard
                  title={item.title}
                  body={item.body}
                  Icon={item.icon}
                  isLastColumn={isLastColumn}
                  isLastRow={isLastRow}
                  singleColumn={singleColumn}
                  isCentered={isCenter}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default Why;
