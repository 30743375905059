import React from 'react';
import './accordionStyle.css';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

const XBAccordion = React.memo(({ title, body, isActive, onClick }) => {
  return (
    <div className={`xb_accordion_box ${isActive ? 'active' : ''}`}>
      <div onClick={onClick} className="xb_accordion_label">
        {title}
        {isActive ? <MdKeyboardArrowUp size={26} /> : <MdKeyboardArrowDown size={26} />}
      </div>
      <div className="xb_accordion_content">
        <p>{body}</p>
      </div>
    </div>
  );
});

export default XBAccordion;
