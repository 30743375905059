import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import './CallRoom.css';
const CallRoom = () => {
  const { meetingId } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const username = params.get('user') || 'Your Name';
  const meeting = async (element) => {
    const appId = 88040138;
    const serverSecret = '58fde22718ad3e70923f0b28ec03bbcc';
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appId,
      serverSecret,
      meetingId,
      Date.now().toString(),
      location?.state?.username || username,
    );
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    zp.joinRoom({
      container: element,
      // sharedLinks: [
      //   {
      //     name: "Personal link",
      //     url:
      //       window.location.protocol +
      //       "//" +
      //       window.location.host +
      //       window.location.pathname +
      //       "?roomId=" +
      //       roomId,
      //   },
      // ],
      scenario: {
        mode: ZegoUIKitPrebuilt.GroupCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
      },
    });
  };
  return <div className="myCallContainer" ref={meeting} style={{ width: '100vw', height: '100vh' }}></div>;
};

export default CallRoom;
