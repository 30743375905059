import React from 'react';
import Logo from '../../../../../static/img/xo-bridge-v3.svg';
import ModalPopup from '../../../../../components/ModalPopup';

const PageWrapper = ({ children }) => {
  return (
    <div className="relative h-[100vh] w-full bg-[#B1D8DC] z-[0] !border-box !m-0 !p-6  md:!p-4 overflow-hidden">
      <img
        src={Logo}
        alt="logo"
        className="w-[50%] absolute top-[45%] left-[53%] translate-x-[-50%] translate-y-[-50%] opacity-30 object-cover z-[-1]"
      />
      <ModalPopup />;{children}
    </div>
  );
};

export default PageWrapper;
