import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageWrapper from './components/PageWrapper';
import CallCard from './components/CallCard';
import CallPanel from './components/CallPanel';
import './styles.css';
import JoinCall from './components/JoinCall';
import MyCallCard from './components/MyCallCard';
import { useDispatch, useSelector } from 'react-redux';
import useUnauthorizedCall from './hooks/useUnauthorizedCall';
import { setNetworkStatus } from '../../../redux/globals/actions';
import useJoinCall from './components/JoinCall/hooks/useJoinCall';
import CallCard2 from './components/CallCard2';
import socketService from '../../../services/socket.service';

const getClass = (users) => {
  let numUsers = users + 1;

  if (numUsers === 1) {
    return 'users-1';
  } else if (numUsers === 2) {
    return 'users-2';
  } else if (numUsers === 3) {
    return 'users-3';
  } else if (numUsers === 4) {
    return 'users-4';
  } else if (numUsers === 5) {
    return 'users-5';
  } else if (numUsers === 6) {
    return 'users-6';
  } else if (numUsers === 7) {
    return 'users-7';
  } else if (numUsers === 8) {
    return 'users-8';
  } else if (numUsers === 9) {
    return 'users-9';
  } else if (numUsers > 9) {
    // Handle the case where you have more than 9 users
    return;
  }
};

const UnauthorizedCallUser = () => {
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    if (window.innerWidth <= 1100) {
      return 5;
    }
    return 8;
  });
  const { myCallData, onCall, networkStatus, activeStream, currentMeetingId } = useSelector((state) => state.globals);
  const {
    usersOnCall,
    setUsersOnCall,
    getDeviceStream,
    localStreamRef,
    toggleVideo,
    toggleMic,
    state,
    remoteStreamRef,
    shareScreen,
    stopSharingScreen,
    maximizeRemoteVideo,
    endCall,
    dispatch,
    myVideoTrack,
    myScreenTrack,
    myAudioTrack,
  } = useUnauthorizedCall();

  const { peers } = useJoinCall({ setUsersOnCall });

  const { currentPage } = state;
  useEffect(() => {
    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);
    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, []);
  const reduxDispatch = useDispatch();

  const onOnline = useCallback(() => {
    // setShowOffline(false);
    reduxDispatch(setNetworkStatus(false));
  }, []);

  const onOffline = useCallback(() => {
    // setShowOffline(true);
    reduxDispatch(setNetworkStatus(true));
  }, []);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1100) {
        setItemsPerPage(5);
      } else {
        setItemsPerPage(8);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (usersOnCall?.length < 9) {
      dispatch({ currentPage: 1 });
    }

    if (usersOnCall?.length) {
      usersOnCall?.forEach((user) => {
        let videoSender = user?.peerCall.peerConnection
          .getSenders()
          .find((s) => s.track.kind == activeStream.getVideoTracks()[0].kind);

        videoSender?.replaceTrack(activeStream.getVideoTracks()[0]);

        let audioSender = user?.peerCall?.peerConnection
          .getSenders()
          .find((s) => s.track.kind == myAudioTrack.current.kind);

        audioSender?.replaceTrack(myAudioTrack.current);
      });

      socketService.emit('videoMode', {
        userName: myCallData?.userName,
        meetingId: currentMeetingId,
        videoMode: activeStream.getVideoTracks()[0].enabled,
        peerId: myCallData?.peerId,
      });
      socketService.emit('audioMode', {
        userName: myCallData?.userName,
        meetingId: currentMeetingId,
        audioMode: myAudioTrack.current.enabled,
        peerId: myCallData?.peerId,
      });
    }
  }, [usersOnCall?.length]);

  const totalUsers = usersOnCall?.length;
  const totalPages = Math.ceil(totalUsers / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalUsers);

  let callCardComponent;

  const usersToDisplay = useMemo(
    () => usersOnCall?.filter((user) => user?.stream && user)?.slice(startIndex, endIndex),
    [usersOnCall, startIndex, endIndex],
  );

  const userClass = useMemo(
    () => getClass(usersToDisplay?.filter((user) => user?.stream && user)?.length),
    [usersToDisplay],
  );

  if (usersOnCall?.length < 1) {
    callCardComponent = (
      <div className="flex justify-center items-center h-full w-full">
        <MyCallCard
          singleUser
          state={state}
          toggleMic={toggleMic}
          toggleVideo={toggleVideo}
          localStreamRef={localStreamRef}
          getDeviceStream={getDeviceStream}
        />
      </div>
    );
  } else {
    callCardComponent = (
      <div className={`call-Container ${userClass}`}>
        <MyCallCard
          state={state}
          toggleMic={toggleMic}
          toggleVideo={toggleVideo}
          localStreamRef={localStreamRef}
          getDeviceStream={getDeviceStream}
        />
        {usersToDisplay?.map((user, index) => (
          <CallCard key={index} user={user} maximizeRemoteVideo={maximizeRemoteVideo} usersOnCall={usersOnCall} />
        ))}

        {/* {usersOnCall
          ?.filter((user) => user?.stream && user)
          ?.map((peer, index) => {
            return <CallCard2 key={index} peer={peer} />;
          })} */}
      </div>
    );
  }

  if (!onCall) {
    return (
      <PageWrapper>
        <JoinCall
          state={state}
          toggleMic={toggleMic}
          toggleVideo={toggleVideo}
          setUsersOnCall={setUsersOnCall}
          localStreamRef={localStreamRef}
          getDeviceStream={getDeviceStream}
          myScreenTrack={myScreenTrack}
          myVideoTrack={myVideoTrack}
        />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      {networkStatus && (
        <div className="no-internet">
          <span>No internet connection !!!</span>
        </div>
      )}
      <div className="h-[90%] md:h-[85%] w-full flex justify-center items-center  !mt-[-10px]">{callCardComponent}</div>
      <div className="h-[9%] md:h-[14%] w-full flex items-center justify-center mt-2">
        <CallPanel
          prevScreen={() => dispatch({ currentPage: currentPage - 1 })}
          nextScreen={() => dispatch({ currentPage: currentPage + 1 })}
          isPrevDisabled={currentPage === 1}
          isNextDisabled={currentPage >= totalPages}
          state={state}
          toggleMic={toggleMic}
          toggleVideo={toggleVideo}
          usersOnCall={usersOnCall}
          shareScreen={shareScreen}
          stopSharingScreen={stopSharingScreen}
          endCall={endCall}
          dispatch={dispatch}
        />
      </div>
    </PageWrapper>
  );
};

export default UnauthorizedCallUser;
