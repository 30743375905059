import { Button, Dropdown } from 'antd';
import React from 'react';
import { CgMenuGridO } from 'react-icons/cg';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { RiMenu5Fill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import Logo from '../../../../../static/img/xo-bridge-white-v3.svg';

const Navbar = () => {
  const navigate = useNavigate();

  function toggleMenu() {
    var menuToggle = document.querySelector('.nav-open');
    var navigation = document.querySelector('.nav-content');
    menuToggle.classList.toggle('active');
    navigation.classList.toggle('active');
  }

  function onClose() {
    var menuToggle = document.querySelector('.nav-open');
    var navigation = document.querySelector('.nav-content');
    menuToggle.classList.remove('active');
    navigation.classList.remove('active');
  }

  const items = [
    {
      key: '1',
      label: 'Xolani Meeting',
      onClick: () => {
        navigate('/meet');
      },
    },
  ];
  return (
    <div class="flex justify-center fixed top-0 w-full bg-[#0796A7] h-[70px] shadow-md z-40 nav-top">
      <div className="max-w-[1280px] mx-auto px-6  sm:px-2 md:px-5 lg:px-3 flex  justify-between md:justify-between xl:justify-between items-center text-[#FFF] w-full nav-box">
        <div className="pr-12  pl-0 min-w-[35%]">
          <div onClick={() => navigate('/')}>
            <img className="w-full max-w-[150px] min-w-[100px] xs:max-w-[100px]" src={Logo} alt="Xolani Bridge Logo" />
          </div>
        </div>

        <div className=" flex-1 flex justify-center items-center gap-[40px] text-[1.0rem] font-light sm:text-sm nav-content lg:shadow-2xl">
          <Link className="text-[#fdfdfd]" to="home" smooth={true} duration={500} spy={true} onClick={() => onClose()}>
            <div className="text-[14px] font-medium transition-transform hover:scale-105 hover:text-[#000000] cursor-pointer">
              Home
            </div>
          </Link>

          <Link className="text-[#fdfdfd]" to="about" smooth={true} duration={500} spy={true} onClick={() => onClose()}>
            <div className="text-[14px] font-medium transition-transform hover:scale-105 hover:text-[#000000] cursor-pointer">
              About
            </div>
          </Link>

          <Link
            className="text-[#fdfdfd]"
            to="features"
            smooth={true}
            duration={500}
            spy={true}
            onClick={() => onClose()}
          >
            <div className="text-[14px] font-medium transition-transform hover:scale-105 hover:text-[#000000]  cursor-pointer">
              Features
            </div>
          </Link>

          <Link className="text-[#fdfdfd]" to="faqs" smooth={true} duration={500} spy={true} onClick={() => onClose()}>
            <div className="text-[14px] font-medium transition-transform hover:scale-105 hover:text-[#000000] cursor-pointer">
              FAQs
            </div>
          </Link>

          <Link
            className="text-[#fdfdfd]"
            to="contact-us"
            smooth={true}
            duration={500}
            spy={true}
            onClick={() => onClose()}
          >
            <div className="text-[14px] font-medium transition-transform hover:scale-105 hover:text-[#000000] cursor-pointer">
              Contact
            </div>
          </Link>

          <div className="xo-menu text-[14px] font-medium text-2xl  mx-[-10px] transition-transform hover:scale-110  cursor-pointer">
            <Dropdown
              menu={{ items }}
              placement="bottomRight"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <CgMenuGridO />
            </Dropdown>
          </div>

          <div className="text-[14px] font-medium transition-transform hover:scale-105  ml-auto lg:ml-0">
            <Button
              size="large"
              onClick={() => navigate('/app/auth')}
              className="bg-white hover:text-gray-800 hover:bg-white border-solid border-1 border-primary text-primary dark:text-[#fdfdfd]87 text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-[8px] px-5 py-[5px]"
            >
              Log In
            </Button>
          </div>
          <div className="nav-close" onClick={() => toggleMenu()}>
            <IoCloseCircleOutline />
          </div>
        </div>

        <div className="nav-open">
          <div className="text-[14px] font-medium text-2xl  mr-8 transition-transform hover:scale-110  cursor-pointer">
            <Dropdown
              menu={{ items }}
              placement="bottomRight"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <CgMenuGridO />
            </Dropdown>
          </div>
          <div onClick={() => toggleMenu()}>
            <RiMenu5Fill />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
