import { ReactComponent as MainIcon1 } from '../../../../../assets/icons/main_icon1.svg';
import { ReactComponent as MainIcon2 } from '../../../../../assets/icons/main_icon2.svg';
import { ReactComponent as MainIcon3 } from '../../../../../assets/icons/main_icon3.svg';
import { ReactComponent as MainIcon4 } from '../../../../../assets/icons/main_icon4.svg';
import { ReactComponent as MainIcon5 } from '../../../../../assets/icons/main_icon5.svg';
import { ReactComponent as MainIcon6 } from '../../../../../assets/icons/main_icon6.svg';
import { ReactComponent as InstIcon1 } from '../../../../../assets/icons/inst_icon1.svg';
import { ReactComponent as InstIcon2 } from '../../../../../assets/icons/inst_icon2.svg';
import { ReactComponent as InstIcon3 } from '../../../../../assets/icons/main_icon3.svg';
import { ReactComponent as InstIcon4 } from '../../../../../assets/icons/inst_icon4.svg';
import { ReactComponent as RadIcon1 } from '../../../../../assets/icons/rad_icon1.svg';
import { ReactComponent as RadIcon2 } from '../../../../../assets/icons/rad_icon2.svg';
import { ReactComponent as RadIcon3 } from '../../../../../assets/icons/main_icon3.svg';
import { ReactComponent as RadIcon4 } from '../../../../../assets/icons/main_icon4.svg';
import { ReactComponent as RadIcon5 } from '../../../../../assets/icons/inst_icon4.svg';
import { ReactComponent as RadIcon6 } from '../../../../../assets/icons/rad_icon6.svg';

export const landingConstants = [
  {
    icon: MainIcon1,
    title: 'Seamless Online Platform',
    body: 'Easy-access platform for hospitals & radiologists to collaborate online.',
  },
  {
    icon: MainIcon2,
    title: 'Global Radiologist Network',
    body: 'Access to a diverse and experienced global community of radiologists.',
  },
  {
    icon: MainIcon3,
    title: 'Real Time Collaboration',
    body: 'Real-time collaboration tools for radiologists and hospitals.',
  },
  {
    icon: MainIcon4,
    title: 'Secure Data Exchange',
    body: 'Enhanced security to ensure the safe exchange of medical data.',
  },
  {
    icon: MainIcon5,
    title: 'Timely Diagnosis',
    body: 'Accelerated diagnosis timelines through quick access to radiologists.',
  },
  {
    icon: MainIcon6,
    title: 'Image Archiving and Retrieval',
    body: 'Robust storage and easy retrieval of medical images for future use.',
  },
];

export const radiologistFeatureData = [
  {
    title: 'Work Remotely',
    body: 'Take control of your schedule and work from anywhere, at any time. Our platform gives you the flexibility to own your hours and drive a career that fits your needs',
    icon: RadIcon1,
  },
  {
    title: 'Increased Earning Potential',
    body: "Increase your earnings and gain financial stability with our platform's competitive compensation structure and constant flow of cases.",
    icon: RadIcon2,
  },
  {
    title: 'Work with Radiology Specialist',
    body: 'Collaborate with a community of subspecialist radiologists, sharing knowledge and expertise to enhance medical imaging interpretation and professional growth',
    icon: RadIcon3,
  },
  {
    title: 'Make a Difference in Patient Care',
    body: 'Join us to expand healthcare access to underserved communities in Nigeria, improving patient outcomes and services.',
    icon: RadIcon4,
  },
  {
    title: 'State-of-the-Art Technology',
    body: 'Access advanced image processing and telecommunication tools on XolaniBridge, ensuring seamless, safe, and swift diagnoses.',
    icon: RadIcon5,
  },
  {
    title: 'Streamline Productivity',
    body: 'Streamline your workflow with XolaniBridge, automating administrative tasks to prioritize accurate and timely diagnoses.',
    icon: RadIcon6,
  },
];

export const institutionFeaturesData = [
  {
    title: 'Expert Radiologist Access',
    body: 'Gain round-the-clock access to expert radiologists, providing precise and timely interpretation of your radiology scans and images, every day of the year.',
    icon: InstIcon1,
  },
  {
    title: 'Rapid Turnaround Time for Reports',
    body: 'Experience accurate and reliable results delivered within exceptional turnaround times for urgent and routine cases to ensure timely treatment and expected patient outcomes.',
    icon: InstIcon2,
  },
  {
    title: 'Real Time Collaboration',
    body: 'Directly collaborate with radiologists to clarify findings, discuss treatment options, and ensure seamless patient care.',
    icon: InstIcon3,
  },
  {
    title: 'Cost-Effective Solution',
    body: 'Cut costs and optimize efficiency with our radiology platform, focusing on patient care without added expenses.',
    icon: InstIcon4,
  },
];
