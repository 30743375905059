export const TABS = {
  GENERAL: 'general',
  TELE_RADIOLOGY: 'tele_radiology',
};

export const TAB_ITEMS = {
  GENERAL_1: 'general_1',
  GENERAL_2: 'general_2',
  GENERAL_3: 'general_3',
  GENERAL_4: 'general_4',
  GENERAL_5: 'general_5',
  TELE_RADIOLOGY_1: 'tele_radiology_1',
  TELE_RADIOLOGY_2: 'tele_radiology_2',
  TELE_RADIOLOGY_3: 'tele_radiology_3',
  TELE_RADIOLOGY_4: 'tele_radiology_4',
  TELE_RADIOLOGY_5: 'tele_radiology_5',
};
