import { combineReducers } from 'redux';

import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import dicomReducer from './dicom/reducers';
import { globalReducer } from './globals/reducers';
import usersReducer from './users/reducers';
import studiesReducers from './studies/reducers';
import chatsMessagesReducer from './chats_messages/reducers';
import appReducer from './app/reducers';
import projectReducer from './project/reducers';
import adminReducer from './admin/reducers';

const rootReducers = combineReducers({
  app: appReducer,
  auth: authReducer,
  ChangeLayoutMode,
  dicom: dicomReducer,
  globals: globalReducer,
  user: usersReducer,
  studies: studiesReducers,
  chatsMessages: chatsMessagesReducer,
  projects: projectReducer,
  admin: adminReducer,
});

export default rootReducers;
