import {
  SET_ACTIVE_BRANCH,
  SET_ACTIVE_INSTITUTION,
  SET_ALL_BRANCHES,
  SET_CONTACTS,
  SET_ME_HOSPITAL,
  SET_ME_RADIOLOGIST,
  SET_MODALITY,
  SET_MY_DATA,
  SET_PERMISSION,
  SET_PLANS,
  SET_RELATIONSHIP_TYPE,
} from './types';

const initialState = {
  contacts: [],
  myData: null,
  myRadiologist: null,
  myHospital: null,
  modality: [],
  plans: [],
  activeBranch: {},
  activeInstitution: {},
  branches: [],
  relationship: null,
  permission: {},
};

const usersReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SET_CONTACTS:
      return {
        ...state,
        contacts: data,
      };

    case SET_MY_DATA:
      return {
        ...state,
        myData: data,
      };
    case SET_ME_RADIOLOGIST:
      return {
        ...state,
        myRadiologist: data,
      };
    case SET_ME_HOSPITAL:
      return {
        ...state,
        myHospital: data,
      };
    case SET_MODALITY:
      return {
        ...state,
        modality: data,
      };
    case SET_PLANS:
      return {
        ...state,
        plans: data,
      };
    case SET_ACTIVE_BRANCH:
      return {
        ...state,
        activeBranch: data,
      };
    case SET_ACTIVE_INSTITUTION:
      return {
        ...state,
        activeInstitution: data,
      };
    case SET_ALL_BRANCHES:
      return {
        ...state,
        branches: data,
      };
    case SET_RELATIONSHIP_TYPE:
      return {
        ...state,
        relationship: data,
      };
    case SET_PERMISSION:
      return {
        ...state,
        permission: data,
      };

    default:
      return state;
  }
};

export default usersReducer;
