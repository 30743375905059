import {
  SET_ACTIVE_STREAM,
  SET_CALLER,
  SET_CALLING,
  SET_CALL_TYPE,
  SET_CURRENT_MEETING_ID,
  SET_DRAWER,
  SET_DRAWER_CHILDREN,
  SET_INCOMING_CALL,
  SET_LOCAL_STREAM,
  SET_MY_CALL_DATA,
  SET_NETWORK_STATUS,
  SET_ON_CALL,
  SET_OUTGOING_CALL,
  SET_PEER,
  SET_POPUP_CHILDREN,
  SET_REMOTE_PEER,
  SET_REMOTE_STREAM,
  SET_SECOND_POPUP_CHILDREN,
  SET_SHOW_POPUP,
  SET_SHOW_SECOND_POPUP,
  SET_SOCKET,
  SET_TRACKS,
  SET_USERS_ON_CALL,
} from './types';

const defaultState = {
  drawer: false,
  drawerChildren: null,
  localStream: null,
  onCall: false,

  outgoingCall: null,
  incomingCall: null,
  caller: false,
  remoteStream: null,
  localStream: null,
  activeStream: null,
  socket: null,
  peer: null,
  calling: null,

  showPopup: false,
  popupChildren: null,
  remotePeer: null,
  tracks: null,

  showSecondPopup: false,
  secondPopupChildren: null,

  width: null,

  callType: 'voice',
  usersOnCall: [],
  currentMeetingId: null,
  myCallData: { userName: '', peerId: '' },
  networkStatus: false,

  showAlert: false,
  alertChildren: null,
};

export const globalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_DRAWER:
      return { ...state, drawer: action.payload };
    case SET_DRAWER_CHILDREN:
      return {
        ...state,
        drawerChildren: action.payload,
        size: action.size,
      };
    case SET_LOCAL_STREAM:
      return {
        ...state,
        localStream: action.payload,
      };
    case SET_ACTIVE_STREAM:
      return {
        ...state,
        activeStream: action.payload,
      };
    case SET_ON_CALL:
      return {
        ...state,
        onCall: action.payload,
      };

    case SET_SHOW_POPUP:
      return { ...state, showPopup: action.payload };
    case SET_POPUP_CHILDREN:
      return {
        ...state,
        popupChildren: action.payload,
      };
    case SET_SOCKET:
      return { ...state, socket: action.payload };
    case SET_PEER:
      return { ...state, peer: action.payload };
    case SET_CALLING:
      return { ...state, calling: action.payload };

    case SET_OUTGOING_CALL:
      return { ...state, outgoingCall: action.payload };
    case SET_INCOMING_CALL:
      return { ...state, incomingCall: action.payload };
    case SET_CALLER:
      return { ...state, caller: action.payload };
    case SET_REMOTE_STREAM:
      return { ...state, remoteStream: action.payload };
    case SET_REMOTE_PEER:
      return { ...state, remotePeer: action.payload };
    case SET_TRACKS:
      return { ...state, tracks: action.payload };
    case SET_CALL_TYPE:
      return { ...state, callType: action.payload };
    case SET_USERS_ON_CALL:
      return { ...state, usersOnCall: action.payload };
    case SET_CURRENT_MEETING_ID:
      return { ...state, currentMeetingId: action.payload };
    case SET_MY_CALL_DATA:
      return { ...state, myCallData: action.payload };
    case SET_NETWORK_STATUS:
      return { ...state, networkStatus: action.payload };

    case SET_SHOW_SECOND_POPUP:
      return { ...state, showSecondPopup: action.payload };

    case SET_SECOND_POPUP_CHILDREN:
      return {
        ...state,
        secondPopupChildren: action.payload,
      };

    default:
      return state;
  }
};
