export const SET_STUDIES = 'SET_STUDIES';
export const SET_MY_STUDIES = 'SET_MY_STUDIES';
export const SET_TOTAL_STUDIES = 'SET_TOTAL_STUDIES';
export const SET_AVAILABLE_STUDIES = 'SET_AVAILABLE_STUDIES';
export const SET_BACKLOGS = 'SET_BACKLOGS';
export const SET_CONTRACTS = 'SET_CONTRACTS';
export const SET_SHARED = 'SET_SHARED';
export const SET_BRANCH_STUDIES = 'SET_BRANCH_STUDIES';
export const SET_BRANCH_CONTRACTS = 'SET_BRANCH_CONTRACTS';
export const SET_VIEWER_PATIENT_STUDIES = 'SET_VIEWER_PATIENT_STUDIES';
export const SET_VIEWER_STUDY = 'SET_VIEWER_STUDY';
export const SET_AFFILIATE_STUDY = 'SET_AFFILIATE_STUDY';
export const SET_STUDY_ID = 'SET_STUDY_ID';
export const SET_STUDY_ID_ARRAY = 'SET_STUDY_ID_ARRAY';
export const SET_SELECTED_ROWS = 'SET_SELECTED_ROWS';
