import { Badge, Button, Tooltip } from 'antd';
import React from 'react';
import { BsRecordBtn } from 'react-icons/bs';
import { IoChatbubbles, IoCopy, IoInformationCircleOutline, IoPerson, IoPersonAdd } from 'react-icons/io5';
import {
  MdArrowDownward,
  MdArrowForwardIos,
  MdCancel,
  MdDownload,
  MdMicOff,
  MdOutlineArrowBackIos,
  MdOutlineMic,
  MdPhoneDisabled,
  MdScreenShare,
  MdStopScreenShare,
  MdVideocam,
  MdVideocamOff,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import useCallPanel from './hooks/useCallPanel';
import Chats from '../Chats';
import { setPopupChildren, togglePopup } from '../../../../../redux/globals/actions';
import { copyText } from '../../../../../utility/helpers';

const CallPanel = ({
  prevScreen,
  nextScreen,
  isPrevDisabled,
  isNextDisabled,
  state,
  toggleVideo,
  usersOnCall,
  toggleMic,
  shareScreen,
  stopSharingScreen,
  endCall,
  dispatch,
}) => {
  const {
    onStartScreenRecording,
    handleStopRecording,
    recording,
    state: recordingsState,
    mediaElementRef,
    removeFile,
    downloadScreenshot,
    isCaptured,
  } = useCallPanel();
  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  const reduxDispatch = useDispatch();
  const { screenMode, audioMode, videoMode, newMessages } = state;
  const { currentMeetingId } = useSelector((state) => state.globals);

  const handleChat = () => {
    reduxDispatch(setPopupChildren(<Chats dispatch={dispatch} />));
    reduxDispatch(togglePopup(true));
    dispatch({ newMessages: 0 });
  };
  return (
    <div className="flex justify-center relative ">
      {isCaptured && (
        <div
          className="media-element bg-[#27272b] p-5 rounded-[14px] border border-primary bottom-0 w-64 right-1/3 left-1/3 absolute"
          onContextMenu={handleContextMenu}
        >
          {recordingsState?.videoFile && (
            <video width="100%" height="100%" className="border-primary border" ref={mediaElementRef} controls>
              <source src={URL.createObjectURL(recordingsState?.videoFile)} type="video/mp4" />
            </video>
          )}
          {recordingsState?.imageFile && (
            <img width="100%" height="100%" className="border-primary border" src={recordingsState?.imageFile} />
          )}
          <div className="relative w-full pt-2 flex justify-between">
            {recordingsState?.imageFile && (
              <Button
                className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-[20px] py-[5px]"
                type="primary"
                size="small"
                title="Download"
                onClick={() => {
                  downloadScreenshot();
                }}
              >
                <MdDownload />
              </Button>
            )}
            {(recordingsState?.imageFile || recordingsState?.videoFile) && (
              <Button
                className="bg-red-500 hover:bg-white hover:text-red-500 border-solid border-1 border-red-500 text-white dark:text-white87 text-[14px] font-semibold leading-[22px] inline-flex items-center justify-center rounded-full px-[20px] py-[5px]"
                type="warning"
                size="small"
                title="Remove"
                onClick={() => {
                  removeFile();
                }}
              >
                <MdCancel />
              </Button>
            )}
          </div>
        </div>
      )}
      <div className="bg-white/50 flex md:w-[80vw] ">
        <div className="  flex justify-center md:justify-start items-center gap-4  md:px-2 px-8 py-2  rounded-lg backdrop-blur-xl shadow-2xl overflow-auto relative  md:overflow-x-auto ">
          <div className="flex flex-col justify-center items-center">
            <Tooltip
              title={
                <p className="bg-gray-200 p-1 flex align-middle items-center">
                  <strong>Code:&nbsp;</strong> {currentMeetingId}
                  <span
                    className="pl-2 cursor-pointer hover:scale-105"
                    onClick={() => copyText('Meeting code', currentMeetingId)}
                  >
                    <IoCopy size={18} />
                  </span>
                </p>
              }
            >
              <Button className="flex flex-col w-12 h-12 items-center align-middle justify-center rounded-full hover:scale-105 bg-primary text-white ">
                <IoInformationCircleOutline size={25} />
              </Button>
            </Tooltip>
          </div>

          <div className="flex flex-col justify-center items-center">
            <Tooltip title={audioMode ? 'Mute' : 'Unmute'}>
              <Button
                className={`flex flex-col w-12 h-12 items-center align-middle justify-center rounded-full hover:scale-105 ${
                  audioMode ? 'bg-primary' : 'bg-yellow-700 hover:bg-yellow-700 border-yellow-700'
                }  text-white `}
                onClick={toggleMic}
              >
                {audioMode ? <MdOutlineMic size={25} /> : <MdMicOff size={25} />}
              </Button>
            </Tooltip>
          </div>

          {/* {callType == 'video' && ( */}
          <div className="flex flex-col justify-center items-center">
            <Tooltip title={videoMode ? 'Turn off Camera' : 'Turn on Camera'}>
              <Button
                className={`flex flex-col w-12 h-12 items-center align-middle justify-center rounded-full hover:scale-105 ${
                  videoMode ? 'bg-primary' : 'bg-yellow-700 hover:bg-yellow-700 border-yellow-700'
                }  text-white `}
                onClick={toggleVideo}
              >
                {videoMode ? <MdVideocam size={20} /> : <MdVideocamOff size={20} />}
              </Button>
            </Tooltip>
          </div>
          {/* )} */}

          {
            // callType == 'video' ? (
            screenMode ? (
              <div className="flex flex-col justify-center items-center">
                <Tooltip title={'Stop Sharing'}>
                  <Button
                    className="flex flex-col w-12 h-12 items-center align-middle justify-center rounded-full hover:scale-105 bg-yellow-700 hover:bg-yellow-700 border-yellow-700 text-white "
                    onClick={stopSharingScreen}
                  >
                    <MdStopScreenShare size={25} />
                  </Button>
                </Tooltip>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center">
                <Tooltip title={'Share screen'}>
                  <Button
                    className="flex flex-col w-12 h-12 items-center align-middle justify-center rounded-full hover:scale-105 bg-primary text-white "
                    onClick={shareScreen}
                  >
                    <MdScreenShare size={25} />
                  </Button>
                </Tooltip>
              </div>
            )
            // ) : null
          }

          {!recording ? (
            <div className="flex flex-col justify-center items-center relative">
              <Tooltip title={'Record Screen'}>
                <Button
                  onClick={onStartScreenRecording}
                  className="flex flex-col w-12 h-12 items-center align-middle justify-center rounded-full hover:scale-105 bg-primary text-white "
                >
                  <BsRecordBtn size={20} />
                </Button>
              </Tooltip>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center relative">
              <Tooltip title={'Stop Recording'}>
                <Button
                  onClick={handleStopRecording}
                  className="flex flex-col w-12 h-12 items-center align-middle justify-center rounded-full hover:scale-105 bg-yellow-700 hover:bg-yellow-700 border-yellow-700 text-white  "
                >
                  <BsRecordBtn className="recording-btn" size={25} />
                </Button>
              </Tooltip>
            </div>
          )}

          <div className="flex flex-col justify-center items-center relative">
            <Tooltip title={'Participants'}>
              <Button className="flex flex-col w-12 h-12 items-center align-middle justify-center rounded-full hover:scale-105 bg-primary text-white ">
                <Badge count={Number(usersOnCall?.length) + 1} overflowCount={10}>
                  <IoPerson size={25} className="text-white" />
                </Badge>
              </Button>
            </Tooltip>
          </div>

          <div className="flex flex-col justify-center items-center relative">
            <Tooltip title={'Chats'}>
              <Button
                className="flex flex-col w-12 h-12 items-center align-middle justify-center rounded-full hover:scale-105 bg-primary text-white "
                onClick={handleChat}
              >
                <Badge count={newMessages} overflowCount={10}>
                  <IoChatbubbles size={25} className="text-white " />
                </Badge>
              </Button>
            </Tooltip>
          </div>

          {/* <div className="flex flex-col justify-center items-center relative">
          <Tooltip title={'Add contact to call'}>
            <Button
              className="flex flex-col w-12 h-12 items-center align-middle justify-center rounded-full hover:scale-105 bg-primary text-white "
                onClick={() => {
                  dispatch(togglePopup(true));
                  dispatch(setPopupChildren(<InviteTocall usersOnCall={usersOnCall} />));
                }}
            >
              <IoPersonAdd size={25} className="text-white" />
            </Button>
          </Tooltip>
        </div> */}

          <div className="flex flex-col justify-center items-center">
            <Tooltip title={'End call'}>
              <Button
                onClick={endCall}
                className="flex flex-col w-16 h-12 items-center align-middle justify-center rounded-full hover:scale-105 bg-red-500 hover:bg-red-500 hover:border-red-500 text-white "
              >
                <MdPhoneDisabled color="white" size={20} />
              </Button>
            </Tooltip>
          </div>

          {/* <div className="flex flex-col justify-center items-center sm:static md:static absolute right-10 bottom-2 animate-pulse">
          <Tooltip title={'Hide video panel'}>
            <Button
              //   onClick={() => setIsMin(!isMin)}
              className="flex flex-col w-12 h-12 items-center align-middle justify-center rounded-full hover:scale-105 bg-primary text-white "
            >
              <MdArrowDownward size={25} />
            </Button>
          </Tooltip>
        </div> */}
        </div>
        <div className="flex items-center pr-2">
          {!isPrevDisabled && (
            <div className="flex flex-col justify-center items-center">
              <Tooltip title="Previous Screen">
                <Button
                  type="link"
                  onClick={() => prevScreen()}
                  className=" ml-4 pr-4 m-0 bg-primary flex items-center border-none  "
                >
                  <MdOutlineArrowBackIos className="text-2xl text-white font-semibold" />
                </Button>
              </Tooltip>
            </div>
          )}
          {!isNextDisabled && (
            <div className="flex flex-col justify-center items-center">
              <Tooltip title="Next Screen">
                <Button
                  type="link"
                  onClick={() => nextScreen()}
                  className="ml-4 pr-4 m-0 bg-primary flex items-center border-none  "
                >
                  <MdArrowForwardIos className="text-2xl text-white font-semibold" />
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CallPanel;
