import React from 'react';
import './xbButtonStyle.css';

const XBButton = React.memo(({ children, type, onClick, custom, customBg, customColor, icon }) => {
  if (!custom) {
    return (
      <div
        onClick={onClick}
        className={`xb_button_container ${type === 'secondary' ? 'xb_button_secondary' : 'xb_button_primary'} `}
      >
        <span className="xb_button_icon"></span>
        <span className="xb_button_text">{children}</span>
      </div>
    );
  } else {
    return (
      <div onClick={onClick} style={{ backgroundColor: customBg }} className={` text-[#FFF] xb_button_container  `}>
        <span className="xb_button_text">{children}</span>
        <span className="xb_button_icon_2">{icon}</span>
      </div>
    );
  }
});

export default XBButton;
