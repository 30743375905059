import React from 'react';
import { MdOutlineArrowRightAlt } from 'react-icons/md';
import XBButton from '../../../../../components/XBButton';

const ServiceCard2 = ({ title, body, image, background, btnBg, btnText, onClick }) => {
  return (
    <div
      style={{ backgroundColor: background }}
      className={`w-[100%] h-[410px] sm:h-auto rounded-[1rem] p-6  flex sm:flex-col gap-2 sm:gap-8 items-end`}
    >
      <div className="flex flex-col gap-4 ">
        <h4 className="mb-0 text-[#000000] text-[1.5rem] font-semibold">{title}</h4>
        <p className="mb-2 text-[#596061] text-[1rem] font-medium">{body}</p>
        <XBButton onClick={onClick} custom customBg={btnBg} icon={<MdOutlineArrowRightAlt size={22} />}>
          {btnText}
        </XBButton>
      </div>

      <img
        src={image}
        alt={title}
        className="w-[45%] sm:w-[100%] sm:h-[160px] h-[100%] rounded-[1rem] object-cover"
        loading="lazy"
      />
    </div>
  );
};

export default ServiceCard2;
