import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { MdEmail, MdLocalPhone, MdLocationOn } from 'react-icons/md';
import { FaLinkedinIn } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';
import './contactStyle.css';
import CircleVector from '../../../../../assets/icons/circle_vector.svg';
import userService from '../../../../../services/user.service';

const { TextArea } = Input;

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    setLoading(true);
    userService
      .sendVisitorsMessage({
        name: values.name,
        email: values.email,
        subject: values.subject,
        message: values.message,
      })
      .then((res) => {
        message.success(res?.message, [10]);
        console.log(res);
        form.resetFields();
        form.setFieldsValue({});
      })
      .catch((error) => {
        message.error(error?.errors || error?.message, [10]);
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="contact_us_container  py-20  px-4">
      <div className="contact_left_box  p-10">
        <div className="">
          <div>
            <h3 className="contact_us_head ">Contact us</h3>
            <p className="contact_us_head_sub">
              Fill up the form and our friendly team will get back to you within 24hrs.
            </p>
          </div>
          <div className="mt-10">
            <span className="contact_item">
              <MdLocalPhone style={{ fontSize: '25px' }} />
              <p> +234 904 000 4400</p>
            </span>
            <span className="contact_item">
              <MdEmail style={{ fontSize: '25px' }} />
              <p>hello@xolanihealth.com</p>
            </span>
            <span className="contact_item">
              <MdLocationOn style={{ fontSize: '25px' }} />
              <p> No. 5, Kwaji Close, Maitama - Abuja, Nigeria.</p>
            </span>
          </div>
        </div>
        <div className="contact_social">
          <span>
            <MdEmail size={20} />
          </span>
          <span>
            <IoLogoWhatsapp size={20} />
          </span>
          <span>
            <FaLinkedinIn size={20} />
          </span>
        </div>
        <img src={CircleVector} alt="vector" className="img_vector" loading="lazy" />
      </div>
      <Form form={form} onFinish={handleSubmit} layout="vertical" className="flex-1 mt-4 py-6">
        <Form.Item
          label="Name"
          name="name"
          className="mb-[16px] [&>div>div]:pb-[4px] [&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium w-full"
          rules={[{ required: true, message: 'Please input your name!' }]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item>
        <Form.Item
          label="Email Address"
          name="email"
          className="mb-[16px] [&>div>div]:pb-[4px] [&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium w-full"
          rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
        >
          <Input placeholder="Enter your email address" />
        </Form.Item>

        <Form.Item
          label="Subject"
          name="subject"
          className="mb-[16px] [&>div>div]:pb-[4px] [&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium w-full"
          rules={[{ required: true, message: 'Please input your subject' }]}
        >
          <Input placeholder="Enter appropriate title for your message" />
        </Form.Item>
        <Form.Item
          name="message"
          label="Message"
          className="mb-[16px] [&>div>div]:pb-[4px]"
          rules={[{ required: true, message: 'Please input your message!' }]}
        >
          <TextArea placeholder="Leave us a message" rows={6} />
        </Form.Item>
        <div className="flex justify-end">
          <Button
            className="text-sm font-medium text-white border-none h-[40px] flex items-center justify-center rounded-[10px] px-[20px]"
            style={{ backgroundColor: '#0796A7' }}
            size="large"
            htmlType="submit"
            loading={loading}
          >
            Send Your Message
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ContactUs;
