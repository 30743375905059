import {
  SET_ADMIN_INSTITUTION_ID,
  SET_ADMIN_INSTITUTION_RADIOGRAPHER,
  SET_ADMIN_INSTITUTION_RADIOLOGIST,
  SET_ADMIN_INSTITUTION_STUDIES,
  SET_ADMIN_INSTITUTIONS,
  SET_ADMIN_PROSPECTS,
  SET_ADMIN_RADIOLOGISTS,
  SET_ADMIN_STUDIES,
  SET_ADMIN_USERS,
} from './types';

const initialState = {
  institutions: [],
  radiologists: [],
  studies: [],
  users: [],
  institutionId: null,
  institutionStudies: [],
  institutionRadiologists: [],
  institutionRadiographers: [],
  prospects: [],
};

const adminReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case SET_ADMIN_INSTITUTION_ID:
      if (state.institutionId !== data) {
        return {
          ...state,
          institutionId: data,
          institutionRadiographers: [],
          institutionRadiologists: [],
          institutionStudies: [],
        };
      }
      return state;

    case SET_ADMIN_INSTITUTIONS:
      return {
        ...state,
        institutions: data,
      };
    case SET_ADMIN_RADIOLOGISTS:
      return {
        ...state,
        radiologists: data,
      };
    case SET_ADMIN_STUDIES:
      return {
        ...state,
        studies: data,
      };
    case SET_ADMIN_USERS:
      return {
        ...state,
        users: data,
      };
    case SET_ADMIN_INSTITUTION_STUDIES:
      return {
        ...state,
        institutionStudies: data,
      };
    case SET_ADMIN_INSTITUTION_RADIOLOGIST:
      return {
        ...state,
        institutionRadiologists: data,
      };
    case SET_ADMIN_INSTITUTION_RADIOGRAPHER:
      return {
        ...state,
        institutionRadiographers: data,
      };
    case SET_ADMIN_PROSPECTS:
      return {
        ...state,
        prospects: data,
      };
    default:
      return state;
  }
};

export default adminReducer;
