import { Modal } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPopupChildren, togglePopup } from '../../redux/globals/actions';
import './styles.css';

function ModalPopup() {
  const { showPopup, popupChildren } = useSelector((state) => state.globals);
  const dispatch = useDispatch();
  const { onCall, incomingCall } = useSelector((state) => state.globals);

  useEffect(() => {
    if (!showPopup) {
      dispatch(setPopupChildren(null));
    }
  }, [showPopup]);

  const onClose = () => {
    dispatch(togglePopup(false));
  };
  return (
    <Modal
      centered
      open={showPopup}
      onCancel={() => {
        if (incomingCall) {
          return null;
        } else {
          return onClose();
        }
      }}
      footer={<></>}
      closeIcon={false}
      closable={true}
      destroyOnClose={true}
    >
      {popupChildren}
    </Modal>
  );
}

export default ModalPopup;
