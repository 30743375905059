/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */

import dicomParser from 'dicom-parser';
import JSZip from 'jszip';
const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

const idGenerator = (events, length = 1) => {
  const arrayData = [];
  events.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length ? `${'0'.repeat(length - number.length)}${number}` : number;
};

const dateObjToString = (date, format) => {
  const year = date?.getFullYear()?.toString();
  let month = ((date?.getMonth() || 0) + 1)?.toString();
  let day = String(date?.getDate());

  if (year === 'NaN' || month === 'NaN' || day === 'NaN') return null;

  month = month?.length === 1 ? `0${month}` : month;
  day = day?.length === 1 ? `0${day}` : day;

  return format
    .replace(/YYYY|yyyy/g, year)
    .replace(/MM|mm/g, month)
    .replace(/DD|dd/g, day);
};

const blobToFile = (theBlob, fileName) => {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  let file = new File([theBlob], fileName, { lastModified: new Date(), type: theBlob.type, size: theBlob.size });

  return file;
};

const blobToDCMFile = (theBlob, fileName) => {
  return new File([theBlob], fileName, { type: 'application/dicom' });
};

const getFileExtFromBase64 = (base64Data) => {
  return base64Data.substring('data:image/'.length, base64Data.indexOf(';base64'));
};

const validateDICOMFile = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    // Read the file as an ArrayBuffer
    fileReader.readAsArrayBuffer(file);

    // Handle file read completion
    fileReader.onload = () => {
      const arrayBuffer = fileReader.result;
      const dataView = new DataView(arrayBuffer);

      // Check the file signature
      const fileSignature = dataView.getUint32(128, false).toString(16);
      if (fileSignature === '42494d44') {
        resolve('Valid DICOM file');
      } else {
        reject(new Error('Invalid DICOM file'));
      }
    };

    // Handle file read error
    fileReader.onerror = () => {
      reject(new Error('Error reading file'));
    };
  });
};

function getFileExtension(filename) {
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase();
}

const readFileAsBinary = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });
};

function haveDistinctParentPatients(data) {
  for (let i = 0; i < data.length; i++) {
    for (let j = i + 1; j < data.length; j++) {
      if (data[i].ParentPatient !== data[j].ParentPatient) {
        return true;
      }
    }
  }
  return false;
}

function getDistinctParentPatients(data) {
  const distinctParentPatients = new Set();
  const result = [];

  for (const item of data) {
    if (!distinctParentPatients.has(item.ParentPatient)) {
      distinctParentPatients.add(item.ParentPatient);
      result.push(item);
    }
  }

  return result;
}

function getDistinctParentStudy(data) {
  const distinctParentStudies = new Set();
  const result = [];

  for (const item of data) {
    if (!distinctParentStudies.has(item.ParentStudy)) {
      distinctParentStudies.add(item.ParentStudy);
      result.push(item);
    }
  }

  return result;
}

function extractInstanceDetails(response) {
  const studies = new Set();

  function traverse(obj) {
    if (Array.isArray(obj)) {
      obj.forEach((item) => {
        traverse(item);
      });
    } else if (typeof obj === 'object' && obj !== null) {
      if (obj.ParentStudy) {
        studies.add(obj.ParentStudy);
      } else {
        Object.values(obj).forEach((value) => {
          if (Array.isArray(value) || (typeof value === 'object' && value !== null)) {
            traverse(value);
          }
        });
      }
    } else if (typeof obj === 'string') {
      studies.add(obj);
    }
  }

  traverse(response);

  return Array.from(studies);
}

function convertFromBytes(bytes) {
  if (bytes === 0 || bytes === null || bytes === undefined) return '0 Bytes';
  // const k = 1024;
  const k = 1000;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (bytes / Math.pow(k, i)).toFixed(i > 0 ? 2 : 0) + ' ' + sizes[i];
}

const parseDicomFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      try {
        const dataSet = dicomParser.parseDicom(new Uint8Array(arrayBuffer));
        const desiredTags = {
          x00100020: 'PatientID',
          x00100010: 'PatientName',
          x00100030: 'PatientBirthDate',
          x00100040: 'PatientSex',
          x00380300: 'PatientHistory',
          x00104000: 'PatientComments',
          x001021b0: 'AdditionalPatientHistory',
          x00080060: 'Modality',
          x00081030: 'StudyDescription',
          x00080020: 'StudyDate',
          x00080030: 'StudyTime',
          x00120010: 'PatientMedicalHistory',
          x0020000d: 'StudyInstanceUID',
          x00080050: 'AccessionNumber',
          x00080080: 'InstitutionName',
          x00200010: 'StudyID',
          x00080090: 'ReferringPhysicianName',
        };

        const patientDetails = {};

        for (const prop in desiredTags) {
          const tagValue = dataSet.string(prop);
          if (tagValue) {
            patientDetails[desiredTags[prop]] = tagValue;
          }
        }

        resolve(patientDetails);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

const handleZipFile = async (file) => {
  const jszip = new JSZip();
  const zip = await jszip.loadAsync(file);
  const dicomFiles = [];

  for (const filename of Object.keys(zip.files)) {
    try {
      const fileData = await zip.files[filename].async('arraybuffer');

      const dataSet = dicomParser.parseDicom(new Uint8Array(fileData));

      // Extract desired tags from the DICOM data
      const desiredTags = {
        x00100020: 'PatientID',
        x00100010: 'PatientName',
        x00100030: 'PatientBirthDate',
        x00100040: 'PatientSex',
        x00380300: 'PatientHistory',
        x00104000: 'PatientComments',
        x001021b0: 'AdditionalPatientHistory',
        x00080060: 'Modality',
        x00081030: 'StudyDescription',
        x00080020: 'StudyDate',
        x00080030: 'StudyTime',
        x00120010: 'PatientMedicalHistory',
        x0020000d: 'StudyInstanceUID',
        x00080050: 'AccessionNumber',
        x00080080: 'InstitutionName',
        x00200010: 'StudyID',
        x00080090: 'ReferringPhysicianName',
      };

      const patientDetails = {};

      for (const prop in desiredTags) {
        const tagValue = dataSet.string(prop);
        if (tagValue) {
          patientDetails[desiredTags[prop]] = tagValue;
        }
      }
      dicomFiles.push(patientDetails);
      // dicomFiles.push(dataSet);
    } catch (error) {
      console.log(error);
    }
  }
  return dicomFiles;
};

const getDataSet = async (file) => {
  if (file.name.endsWith('.zip')) {
    return await handleZipFile(file);
  } else if (file.name.endsWith('.dcm')) {
    return [await parseDicomFile(file)];
  } else {
    throw new Error('Unsupported file type');
  }
};

const getDistinctStudiesFromDataset = (studies) => {
  const uniqueStudies = [];
  const seen = new Set();

  for (const study of studies) {
    if (study.StudyInstanceUID && !seen.has(study.StudyInstanceUID)) {
      uniqueStudies.push(study);
      seen.add(study.StudyInstanceUID);
    }
  }
  return uniqueStudies;
};

function convertDateToDDMMMYYYY(dateStr) {
  // Expecting dateStr in format YYYYMMDD
  const year = dateStr.substring(0, 4);
  const month = dateStr.substring(4, 6);
  const day = dateStr.substring(6, 8);

  // Convert month number to month name
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const monthName = months[parseInt(month, 10) - 1];

  return `${day} ${monthName} ${year}`;
}

function convertTimeToHHMM(timeStr) {
  // Expecting timeStr in format HHMMSS.ffffff
  const hours = timeStr.substring(0, 2);
  const minutes = timeStr.substring(2, 4);
  // Skipping seconds and fractions for simplicity

  return `${hours}:${minutes}`;
}

const getTextContent = (element) => {
  if (typeof element === 'string') return element;
  if (element && element.props && element.props.children) {
    if (Array.isArray(element.props.children)) {
      return element.props.children.join('');
    }
    return element.props.children;
  }
  return '';
};

function getLastDateOfCurrentMonth() {
  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  // Function to get the correct suffix for the day
  const getDayWithSuffix = (day) => {
    if (day > 3 && day < 21) return day + 'th'; // covers 11th to 20th
    switch (day % 10) {
      case 1:
        return day + 'st';
      case 2:
        return day + 'nd';
      case 3:
        return day + 'rd';
      default:
        return day + 'th';
    }
  };

  // Array of month names
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Format the date
  return `${getDayWithSuffix(lastDayOfMonth.getDate())} ${
    months[lastDayOfMonth.getMonth()]
  }, ${lastDayOfMonth.getFullYear()}`;
}

export {
  ellipsis,
  idGenerator,
  dateObjToString,
  blobToFile,
  getFileExtFromBase64,
  blobToDCMFile,
  validateDICOMFile,
  getFileExtension,
  readFileAsBinary,
  haveDistinctParentPatients,
  extractInstanceDetails,
  getDistinctParentPatients,
  getDistinctParentStudy,
  convertFromBytes,
  getDataSet,
  getDistinctStudiesFromDataset,
  convertDateToDDMMMYYYY,
  convertTimeToHHMM,
  getTextContent,
  getLastDateOfCurrentMonth,
};
