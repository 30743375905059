import React from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import './screenStyle.css';

const FullScreenVideoModal = ({ isOpen, onClose, videoSrc }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 h-screen w-full flex justify-center items-center z-40">
      <div className="absolute top-0 left-0 h-screen w-full inset-0 bg-black opacity-70"></div>
      <div className=" bg-black z-50  overflow-hidden screenBox">
        <span
          onClick={onClose}
          style={{ opacity: 100, top: '2rem', right: '3rem' }}
          className="absolute text-red-500 bg-white cursor-pointer z-50 rounded-full"
        >
          <IoMdCloseCircle size={30} />
        </span>

        <video loop muted autoPlay controls className="w-full h-full object-contain" preload="metadata">
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default FullScreenVideoModal;
