import React from 'react';
import HeartIcon from '../../../../../assets/icons/heatbeat.svg';
import Card3 from '../../../../../assets/img/Connection_to_hospitals_medium.webp';
import Card4 from '../../../../../assets/img/Diagnostic_Lab_medium.webp';
import ServiceCard1 from './ServiceCard1';
import ServiceCard2 from './ServiceCard2';
import { useNavigate } from 'react-router-dom';

const OurServices = () => {
  const navigate = useNavigate();
  return (
    <div className="max-w-[1440px] mx-auto  py-20">
      <div className="flex flex-col items-center text-center gap-4 mb-8">
        <h3 className="text-[#596061] text-[2.5rem] font-semibold mb-0">Our Services</h3>
        <img src={HeartIcon} alt="heartbeat" loading="lazy" />
        <p className="text-[1.2rem] text-[#646C6D] font-medium">
          Our services are designed to support Hospitals, Diagnostic Centers, and Radiologists.
        </p>
      </div>
      <div className="max-w-[1280px] mx-auto flex gap-8 justify-between px-4 flex-wrap  mb-8">
        <div className="w-[52%] lg:w-[100%]">
          <ServiceCard2
            image={Card3}
            background="#E5EFF7"
            btnBg="#7CAACE"
            btnText="Learn More"
            title="Hospitals/Diagnostic Centers"
            body="Our solution connects you to a global network of highly skilled radiologists, diverse medical institutions, and diagnostic centers."
            onClick={() => navigate('/institution')}
          />
        </div>
        <div className="w-[42%] lg:w-[100%]">
          <ServiceCard1
            image={Card4}
            background="#F0F0F0"
            btnBg="#B0B0B0"
            btnText="Learn More"
            title="For Radiologists"
            body="Are you a radiologist looking for a better work-life balance, increased earning potential, and remote access to work tools?"
            onClick={() => navigate('/radiologist')}
          />
        </div>
      </div>
    </div>
  );
};

export default OurServices;
