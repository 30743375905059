import { theme } from './theme/themeVariables';

const pathName = typeof window !== 'undefined' ? window.location.pathname : '/';

const config = {
  topMenu: false,
  rtl: false,
  get mainTemplate() {
    return pathName.startsWith('/admin') ? 'darkMode' : 'lightMode';
  },
  theme,
};

export default config;
