import React from 'react';

const WhyCard = ({ title, body, Icon, isLastColumn, isLastRow, singleColumn, isCentered }) => {
  return (
    <div
      className={`overflow-hidden bg-[#F5FFFF] p-6 md-px-6 lg:px-0 h-[300px] flex justify-center items-center 
        ${!singleColumn ? (isCentered ? 'border-l border-r' : isLastColumn ? 'border-l' : 'border-r') : ''} 
        ${isLastRow ? 'border-t' : 'border-b'} 
        border-[#E7E7E7]`}
    >
      <div className="rounded-2xl px-3 pb-2">
        <div className="flex justify-center text-[rgb(7,150,167)]">
          <Icon size={36} />
        </div>
        <div className="flex flex-col gap-y-2">
          <h1 className="font-semibold text-xl text-[#646C6D] text-center pt-6">{title}</h1>
        </div>
        <p className="mb-0 font-normal text-[#646C6D] text-[17px] sm:text-sm md:text-[16px] lg:text-[16px] text-center">
          {body}
        </p>
      </div>
    </div>
  );
};

export default WhyCard;
