import React, { useEffect } from 'react';
import { Button, Tooltip } from 'antd';
import { MdMicOff, MdOutlineMic, MdVideocam, MdVideocamOff } from 'react-icons/md';
import { useSelector } from 'react-redux';

const MyCallCard = ({ singleUser, onJoin, state, localStreamRef, getDeviceStream, toggleMic, toggleVideo }) => {
  const { myCallData, onCall } = useSelector((state) => state.globals);

  const { videoMode, audioMode } = state;

  const { userName: name } = myCallData;

  function getInitials(name) {
    if (!name) return '';

    const trimmedName = name.trim(); // Trim any leading or trailing spaces
    const names = trimmedName.split(' ');
    let initials = '';

    for (let i = 0; i < names.length; i++) {
      initials += names[i][0]?.toUpperCase();
    }

    return initials;
  }
  useEffect(() => {
    getDeviceStream('video');
  }, []);

  return (
    <div
      className={`bg-white rounded-[15px] call-card-un ${singleUser ? 'singleUser' : ''}  ${
        onJoin ? 'onJoin' : ''
      } flex items-center justify-center  relative`}
    >
      <video
        muted={true}
        ref={localStreamRef}
        autoPlay
        playsInline
        style={{
          objectFit: 'cover',
          height: '100%',
          width: '100%',
          borderRadius: '15px',
          transform: 'scalex(-1)',
        }}
        className={`${videoMode ? 'visible' : 'hidden'}`}
      />

      <div
        className={`${
          !videoMode ? 'visible z-[100]' : 'hidden'
        } w-[60%] h-[60%] rounded-full bg-[#EBF6F8] flex items-center justify-center  ${
          singleUser ? 'text-8xl' : 'text-5xl'
        } call-card-un-text`}
      >
        {getInitials(name)}
      </div>

      {!onCall && (
        <div className="absolute bottom-2 flex gap-4">
          <Tooltip title={audioMode ? 'Mute' : 'Unmute'}>
            <Button
              className={`flex flex-col w-12 h-12 items-center align-middle justify-center rounded-full hover:scale-105 ${
                audioMode ? 'bg-transparent' : 'bg-red-500 hover:bg-red-500 border-red-500'
              }  text-primary sm:w-10 sm:h-10`}
              onClick={toggleMic}
            >
              {audioMode ? <MdOutlineMic size={25} /> : <MdMicOff size={25} />}
            </Button>
          </Tooltip>
          <Tooltip title={videoMode ? 'Turn off Camera' : 'Turn on Camera'}>
            <Button
              className={`flex flex-col w-12 h-12 items-center align-middle justify-center rounded-full hover:scale-105 ${
                videoMode ? 'bg-transparent' : 'bg-red-500 hover:bg-red-500 border-red-500'
              }  text-white sm:w-10 sm:h-10`}
              onClick={toggleVideo}
            >
              {videoMode ? <MdVideocam size={20} /> : <MdVideocamOff size={20} />}
            </Button>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default MyCallCard;
