import React from 'react';
import HeartIcon from '../../../../../assets/icons/heatbeat.svg';
import Featured1 from '../../../../../assets/img/1.webp';
import Featured2 from '../../../../../assets/img/2.webp';
import Featured3 from '../../../../../assets/img/3.webp';
import Featured4 from '../../../../../assets/img/4.webp';
import Featured5 from '../../../../../assets/img/5.webp';
import Featured6 from '../../../../../assets/img/6.webp';

const Featured = () => {
  return (
    <section className="max-w-[1280px] mx-auto  py-20 ">
      <div className="flex flex-col items-center text-center gap-4 mb-8">
        <h3 className="text-[#596061] text-[2.5rem] font-semibold mb-0">Featured In</h3>
      </div>
      <div className="w-full overflow-hidden">
        <div className="grid grid-cols-6 gap-6 md:grid-cols-3">
          <img src={Featured1} alt="featured" className="w-full h-[100px] object-fit  " loading="lazy" />
          <img src={Featured2} alt="featured" className="w-full h-[100px] object-fit " loading="lazy" />
          <img src={Featured3} alt="featured" className="w-full h-[100px] object-fit " loading="lazy" />
          <img src={Featured4} alt="featured" className="w-full h-[100px] object-fit " loading="lazy" />
          <img src={Featured5} alt="featured" className="w-full h-[100px] object-fit " loading="lazy" />
          <img src={Featured6} alt="featured" className="w-full h-[100px] object-fit " loading="lazy" />
        </div>
      </div>
    </section>
  );
};

export default Featured;
