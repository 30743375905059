import {
  SET_PROMPT_LOADING,
  SET_SHOW_AVAILABLE,
  SET_SHOW_CONTRACTS,
  SET_SHOW_HOUSE,
  SET_SHOW_INTERPRETERS,
  SET_TOGGLE,
  SET_TOKEN,
} from './types';

const initialState = {
  toggle: window.innerWidth <= 991 ? true : false,
  promptLoading: false,
  token: false,
  showInterpreters: false,
  showHouse: false,
  showAvailable: false,
  showContracts: false,
};

const appReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SET_TOGGLE:
      return {
        ...state,
        toggle: data,
      };
    case SET_PROMPT_LOADING:
      return {
        ...state,
        promptLoading: data,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: data,
      };
    case SET_SHOW_INTERPRETERS:
      return {
        ...state,
        showInterpreters: data,
      };
    case SET_SHOW_HOUSE:
      return {
        ...state,
        showHouse: data,
      };
    case SET_SHOW_AVAILABLE:
      return {
        ...state,
        showAvailable: data,
      };
    case SET_SHOW_CONTRACTS:
      return {
        ...state,
        showContracts: data,
      };
    default:
      return state;
  }
};
export default appReducer;
