export const SET_OFFERS = 'SET_OFFERS';
export const SET_OFFER_BIDS = 'SET_OFFER_BIDS';
export const SET_OFFER_CONTRACT = 'SET_OFFER_CONTRACT';
export const SET_CLOSED_OFFER = 'SET_CLOSED_OFFER';
export const SET_WALLET_TRANSACTION = 'SET_WALLET_TRANSACTION';
export const SET_RADIOGRAPHERS = 'SET_RADIOGRAPHERS';
export const SET_IN_HOUSE = 'SET_IN_HOUSE';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_PACKS = 'SET_PACKS';
export const SET_DOCTORS = 'SET_DOCTORS';
export const SET_INTERPRETERS = 'SET_INTERPRETERS';
export const SET_TEAMS = 'SET_TEAMS';
export const SET_REGULARS = 'SET_REGULARS';
export const SET_BRANCHES = 'SET_BRANCHES';
export const SET_BRANCH_TEAM = 'SET_BRANCH_TEAM';
export const SET_BRANCH_ADMIN_USERS = 'SET_BRANCH_ADMIN_USERS';
export const SET_AFFILIATES = 'SET_AFFILIATES';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
