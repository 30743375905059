import React, { useEffect, useState } from 'react';
import FaqIcon from '../../../../../assets/icons/FAQ.svg';
import XBAccordion from '../../../../../components/XBAccordion';
import { TABS, TAB_ITEMS } from './constants';
import './faqStyles.css';
import XBSearchInput from '../../../../../components/XBSearchInput';

const FAQ = () => {
  const [tab, setTab] = useState(TABS.GENERAL);
  const [tabItem, setTabItem] = useState(TAB_ITEMS.GENERAL_1);

  useEffect(() => {
    if (tab === TABS.GENERAL) {
      setTabItem(TAB_ITEMS.GENERAL_1);
    } else if (tab === TABS.TELE_RADIOLOGY) {
      setTabItem(TAB_ITEMS.TELE_RADIOLOGY_1);
    }
  }, [tab]);

  return (
    <div style={{ maxWidth: '900px' }} className="max-w-[900px] mx-auto  py-20">
      <div className="flex flex-col items-center text-center gap-4 mb-8">
        <div className=" flex flex-wrap justify-center gap-4 faq_header ">
          <h3 className="text-[#596061] text-[2.5rem] font-semibold mb-0  whitespace-nowrap">Frequently Asked</h3>
          <h3 className="text-[#596061] text-[2.5rem] font-semibold mb-0 flex gap-6 ">
            Questions <img src={FaqIcon} alt="FAQ" className="w-[60px] h-[60px] object-cover " loading="lazy" />
          </h3>
        </div>

        <p className="text-[1.2rem] text-[#828A8B] font-medium">Got any questions? We are Here to Help You </p>

        {/* <div className="w-[560px] sm:w-full">
          <XBSearchInput placeholder="Search questions" />
        </div> */}
      </div>
      <div className=" flex justify-center  gap-14 sm:flex-col faq_tab_names mt-10 mb-20">
        <div className="flex justify-center gap-14">
          <span
            onClick={() => setTab(TABS.GENERAL)}
            style={{
              borderBottom: tab === TABS.GENERAL ? '2px solid #068999' : '2px solid transparent',
            }}
            className={`text-[1.1rem] font-medium cursor-pointer ${
              tab === TABS.GENERAL ? 'text-[#068999]' : 'text-[#596061]'
            }`}
          >
            General
          </span>
        </div>
        <div className="flex justify-center gap-14">
          <span
            onClick={() => setTab(TABS.TELE_RADIOLOGY)}
            style={{
              borderBottom: tab === TABS.TELE_RADIOLOGY ? '2px solid #068999' : '2px solid transparent',
            }}
            className={`text-[1.1rem] font-medium cursor-pointer  ${
              tab === TABS.TELE_RADIOLOGY ? 'text-[#068999]   ' : 'text-[#596061]'
            }`}
          >
            Tele-Radiology
          </span>
        </div>
      </div>
      {tab === TABS.GENERAL && (
        <div className="flex flex-col gap-3">
          <XBAccordion
            isActive={tabItem === TAB_ITEMS.GENERAL_1}
            onClick={() => setTabItem(TAB_ITEMS.GENERAL_1)}
            title="What is XolaniBridge?"
            body=" XolaniBridge is a tele-radiology platform that connects hospitals and diagnostic centers with top radiologists for real-time collaboration and access to world-class radiology services."
          />
          <XBAccordion
            isActive={tabItem === TAB_ITEMS.GENERAL_2}
            onClick={() => setTabItem(TAB_ITEMS.GENERAL_2)}
            title="What is Xolani Bridge all about?"
            body="Our proprietary imaging and teleradiology solution designed to integrate seamlessly into clinical workflows. With real-time collaboration features, our platform optimizes the interpretation process, providing hospitals with convenient access to radiologists."
          />
          <XBAccordion
            isActive={tabItem === TAB_ITEMS.GENERAL_3}
            onClick={() => setTabItem(TAB_ITEMS.GENERAL_3)}
            title="How do I get registered on Xolani Bridge?"
            body="All you have to do is head on to our platform at www.xolanibridge.com , sign up either as a radiologists or an institution (Hospital &amp; Diagnostic Centers), Get verified to enable you practice on the platform as a radiologist, Upload your study and be matched with qualified radiologists, make your payment, Receive your report within 1 – 2 hours."
          />
          <XBAccordion
            isActive={tabItem === TAB_ITEMS.GENERAL_4}
            onClick={() => setTabItem(TAB_ITEMS.GENERAL_4)}
            title="What challenges does XolaniBridge address?"
            body="XolaniBridge tackles limited resources, shortages of qualified radiologists, and complex modern cases in radiology services."
          />
          <XBAccordion
            isActive={tabItem === TAB_ITEMS.GENERAL_5}
            onClick={() => setTabItem(TAB_ITEMS.GENERAL_5)}
            title="How does XolaniBridge support radiologists?"
            body="XolaniBridge empowers radiologists to work remotely, increase earnings, and make a greater impact on patient care."
          />
        </div>
      )}

      {tab === TABS.TELE_RADIOLOGY && (
        <div className="flex flex-col gap-3">
          <XBAccordion
            isActive={tabItem === TAB_ITEMS.TELE_RADIOLOGY_1}
            onClick={() => setTabItem(TAB_ITEMS.TELE_RADIOLOGY_1)}
            title="What kind of images can be interpreted through xolanibridge?"
            body="Our teleradiology platform can handle various medical images, including X-rays, CT scans, MRI scans, ultrasound images, etc..."
          />
          <XBAccordion
            isActive={tabItem === TAB_ITEMS.TELE_RADIOLOGY_2}
            onClick={() => setTabItem(TAB_ITEMS.TELE_RADIOLOGY_2)}
            title="How secure is Xolanibridge?"
            body="Xolanibridge platforms utilize robust security measures, such as encryption and secure servers, to protect patient data and ensure HIPAA compliance."
          />
          <XBAccordion
            isActive={tabItem === TAB_ITEMS.TELE_RADIOLOGY_3}
            onClick={() => setTabItem(TAB_ITEMS.TELE_RADIOLOGY_3)}
            title="How does XolaniBridge facilitate real-time collaboration?"
            body="XolaniBridge's platform connects radiologists and healthcare professionals in real-time, enabling seamless collaboration and consultation."
          />
          <XBAccordion
            isActive={tabItem === TAB_ITEMS.TELE_RADIOLOGY_4}
            onClick={() => setTabItem(TAB_ITEMS.TELE_RADIOLOGY_4)}
            title="How does XolaniBridge impact patient care?"
            body=" XolaniBridge enables timely and accurate diagnoses, leading to better patient outcomes."
          />
        </div>
      )}
    </div>
  );
};

export default FAQ;
