import {
  SET_CALL_MESSAGES,
  SET_CALLING_USER,
  SET_CHATS,
  SET_MESSAGES,
  SET_MESSAGES_IN_VIEW,
  SET_MESSAGING_USER,
  SET_UNAUTHORIZED_MESSAGES,
} from './types';

export const setMessagingUser = (data) => ({
  type: SET_MESSAGING_USER,
  data,
});

export const setCallMessages = (data) => ({
  type: SET_CALL_MESSAGES,
  data,
});

export const setCallingUser = (data) => ({
  type: SET_CALLING_USER,
  data,
});

export const setMessagesInView = (data) => ({
  type: SET_MESSAGES_IN_VIEW,
  data,
});

export const setChats = (data) => ({
  type: SET_CHATS,
  data,
});

export const setUnauthorizedMessages = (data) => ({
  type: SET_UNAUTHORIZED_MESSAGES,
  data,
});
