import { message } from 'antd';
import { useEffect } from 'react';

export const setEmail = (email) => {
  sessionStorage.removeItem('email');
  sessionStorage.setItem('email', email);
};

export function getEmail() {
  const email = sessionStorage.getItem('email');

  if (email) return email;
  else return null;
}

export const setToken = (token) => {
  sessionStorage.removeItem('token');
  sessionStorage.setItem('token', token);
};

export function getToken() {
  const token = sessionStorage.getItem('token');

  if (token) return token;
  else return null;
}

export const setCoRead = (data) => {
  const coReaderData = JSON.stringify(data);
  sessionStorage.removeItem('coRead');
  sessionStorage.setItem('coRead', coReaderData);
};

export function getCoRead() {
  const study = sessionStorage.getItem('coRead');

  if (study) return JSON.parse(study);
  else return null;
}

export const setUser = (data) => {
  const userData = JSON.stringify(data);
  sessionStorage.removeItem('user');
  sessionStorage.setItem('user', userData);
};

export function getUser() {
  const userData = sessionStorage.getItem('user');

  if (userData) {
    return JSON.parse(userData);
  } else {
    return null;
  }
}

export const setStudyData = (data) => {
  const studyData = JSON.stringify(data);
  sessionStorage.removeItem('studyData');
  sessionStorage.setItem('studyData', studyData);
};

export function getStudyData() {
  const studyData = sessionStorage.getItem('studyData');

  if (studyData) {
    return JSON.parse(studyData);
  } else {
    return null;
  }
}
export const setStudyViewerData = (data) => {
  const studyViewerData = JSON.stringify(data);
  sessionStorage.removeItem('studyViewerData');
  sessionStorage.setItem('studyViewerData', studyViewerData);
};

export function getStudyViewerData() {
  const studyViewerData = sessionStorage.getItem('studyViewerData');

  if (studyViewerData) {
    return JSON.parse(studyViewerData);
  } else {
    return null;
  }
}

export const setActiveBranchId = (id) => {
  sessionStorage.removeItem('activeBranch');
  sessionStorage.setItem('activeBranch', id);
};

export function getActiveBranchId() {
  const id = sessionStorage.getItem('activeBranch');

  if (id) return Number(id);
  else return null;
}

export const setActiveInstitutionId = (id) => {
  sessionStorage.removeItem('activeInstitution');
  sessionStorage.setItem('activeInstitution', id);
};

export function getActiveInstitutionId() {
  const id = sessionStorage.getItem('activeInstitution');

  if (id) return Number(id);
  else return null;
}

export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('coRead');
  sessionStorage.removeItem('relationshipType');
  sessionStorage.removeItem('activeInstitution');
  sessionStorage.removeItem('activeBranch');
};

export function sortRegular(arr) {
  const regulars = arr.filter((obj) => obj.isRegular === true);

  const notRegulars = arr.filter((obj) => obj.isRegular !== true);

  const sortedArr = [...regulars, ...notRegulars];

  return sortedArr;
}
export const createMeetingId = () => {
  let dt = new Date().getTime();
  const meetingId = 'xxxx-yxxx'.replace(/[xy]/g, (c) => {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return meetingId;
};

export const copyText = (type, text) => {
  if (text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success(`${type} successfully copied to clipboard.`);
      })
      .catch((e) => {
        message.error(`Oops! Couldn't copy to clipboard.`);
      });
  } else {
    message.info(`Oops! Textfield is empty.`);
  }
};

export const getInitials = (name) => {
  if (!name) return '';

  const trimmedName = name.trim(); // Trim any leading or trailing spaces
  const names = trimmedName.split(' ').filter(Boolean); // Filter out empty parts
  let initials = '';

  // Limit the loop to the first two names
  for (let i = 0; i < Math.min(2, names.length); i++) {
    initials += names[i][0]?.toUpperCase();
  }

  return initials;
};

export function generateGreeting() {
  const currentHour = new Date().getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return 'Good Morning';
  } else if (currentHour >= 12 && currentHour < 17) {
    return 'Good Afternoon';
  } else {
    return 'Good Evening';
  }
}
