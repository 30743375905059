import React from 'react';
import { MdOutlineArrowRightAlt } from 'react-icons/md';
import XBButton from '../../../../../components/XBButton';

const ServiceCard1 = ({ title, body, image, background, btnBg, btnText, onClick }) => {
  return (
    <div
      style={{ backgroundColor: background }}
      className={`w-[100%] h-[410px] sm:h-auto p-6  flex flex-col gap-8 rounded-[1rem]`}
    >
      <div className="flex flex-col gap-4">
        <h4 className="mb-0 text-[#000000] text-[1.5rem] font-semibold">{title}</h4>
        <p className="mb-0 text-[#596061] text-[1rem] font-medium">{body}</p>
        <XBButton onClick={onClick} custom customBg={btnBg} icon={<MdOutlineArrowRightAlt size={22} />}>
          {btnText}
        </XBButton>
      </div>

      <img src={image} alt={title} className="h-[160px] rounded-[1rem] object-cover" loading="lazy" />
    </div>
  );
};

export default ServiceCard1;
