import React, { useEffect, useRef } from 'react';
import './style.css';
import { MdOutlineMic, MdMicOff, MdFullscreen } from 'react-icons/md';
import { Button, Tooltip, Typography } from 'antd';
const { Text } = Typography;
const CallCard = ({ user, singleUser, maximizeRemoteVideo, usersOnCall }) => {
  const { userName: name } = user || {};
  const ref = useRef(null);
  function getInitials(name) {
    if (!name) return '';

    const trimmedName = name.trim(); // Trim any leading or trailing spaces
    const names = trimmedName.split(' ');
    let initials = '';

    for (let i = 0; i < names.length; i++) {
      initials += names[i][0]?.toUpperCase();
    }

    return initials;
  }
  useEffect(() => {
    if (user?.stream) {
      ref.current.srcObject = user.stream;
    }
  }, []);

  return (
    <div
      id={`remote_user_${user?.peerId}`}
      className={`bg-white rounded-[15px] call-card-un flex items-center justify-center relative`}
    >
      <Text ellipsis={true} className="absolute top-2 left-2 w-1/2 text-ellipsis text-white z-10">
        {name}
      </Text>
      {user?.audioMode ? (
        <Button
          onClick={() => null}
          className="rounded-full absolute right-12 top-2 flex flex-col justify-center items-center w-8 h-8 hover:bg-transparent border border-green-500 text-green-500 bg-transparent z-10"
        >
          <MdOutlineMic size={20} className="text-green-500" />
        </Button>
      ) : (
        <Button
          onClick={() => null}
          className="rounded-full absolute right-12 top-2 flex flex-col justify-center items-center w-8 h-8 hover:bg-transparent border border-red-500 text-red-500 bg-transparent z-10"
        >
          <MdMicOff size={20} className="text-red-500" />
        </Button>
      )}
      <Tooltip title="Full screen" placement="top">
        <Button
          onClick={() => maximizeRemoteVideo(user?.peerId)}
          className="rounded-md absolute right-2 top-2 flex flex-col justify-center items-center w-8 h-8 hover:bg-transparent border border-white text-white bg-transparent z-10"
        >
          <MdFullscreen size={20} className="hover:scale-110" />
        </Button>
      </Tooltip>

      <video
        id={`remote_user_${user?.peerId}_video`}
        ref={ref}
        autoPlay
        playsInline
        style={{
          objectFit: 'cover',
          height: '100%',
          width: '100%',
          borderRadius: '15px',
          backgroundColor: 'black',
          transform: 'scalex(-1)',
        }}
        className={`${user?.stream ? 'visible' : 'hidden'}`}
      />

      <div
        className={`${
          !user?.stream ? 'visible' : 'hidden'
        } w-[60%] h-[60%] rounded-full bg-[#EBF6F8] flex items-center justify-center  ${
          singleUser ? 'text-8xl' : 'text-5xl'
        } call-card-un-text`}
      >
        {getInitials(name)}
      </div>
    </div>
  );
};

export default CallCard;
