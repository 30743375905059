import { message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentMeetingId, setMyCallData } from '../../../../../../redux/globals/actions';
import peerService from '../../../../../../services/peer.service';
import socketService from '../../../../../../services/socket.service';
import Peer from 'simple-peer';

const useJoinCall = ({ setUsersOnCall, audioMode, videoMode }) => {
  const { myCallData, localStream, currentMeetingId } = useSelector((state) => state.globals);
  const reduxDispatch = useDispatch();

  const [peers, setPeers] = useState([]);

  const peersRef = useRef([]);

  useEffect(() => {
    socketService.connect();
    peerService.init();
    peerService.on('open', (peerId) => {
      reduxDispatch(setMyCallData({ ...myCallData, peerId }));
    });

    peerService.on('disconnected', () => {
      peerService.reconnect();
    });
  }, []);

  const updateConnectedUser = (user, call, stream) => {
    setUsersOnCall((prevUsers) => {
      const userExists = prevUsers?.some((user) => user?.peerCall?.peer === call?.peer);
      if (!userExists) {
        return [
          ...prevUsers,
          {
            ...user,
            stream,
            peerCall: call,
            audioMode: stream?.getAudioTracks()[0]?.enabled,
            videoMode: stream?.getVideoTracks()[0]?.enabled,
          },
        ];
      } else {
        return prevUsers?.map((user) => {
          if (user?.callPeer?.peer === call?.peer) {
            return {
              ...user,
              stream,
              peerCall: call,
              audioMode: stream?.getAudioTracks()[0]?.enabled,
              videoMode: stream?.getVideoTracks()[0]?.enabled,
            };
          }
          return user;
        });
      }
    });
  };

  const handleJoinCall = (userName, meetingId) => {
    if (!myCallData?.peerId?.length || !localStream) {
      message.info('Unable to join meeting. Please try again!');
      return;
    }

    reduxDispatch(setMyCallData({ ...myCallData, userName }));
    reduxDispatch(setCurrentMeetingId(meetingId));

    peerService.on('call', (call) => {
      call.answer(localStream);

      const data = JSON.parse(call.metadata);
      call.off('stream');
      call.on('stream', (remoteUserStream) => {
        updateConnectedUser(data.user, call, remoteUserStream);
      });

      call.on('error', (err) => {
        console.error('Call error on initiate:', err);
      });
    });

    socketService.on('join_unauthorized_call', (user) => {
      setTimeout(() => {
        const call = peerService.call(user?.peerId, localStream, {
          metadata: JSON.stringify({
            user: { ...myCallData, userName, audioMode, videoMode },
            callType: 'video',
            meetingId,
          }),
        });

        const data = JSON.parse(call.metadata);
        call.off('stream');
        call.on('stream', (remoteUserStream) => {
          updateConnectedUser(user, call, remoteUserStream);
        });

        call.on('error', (err) => {
          console.log('call error here', err);
        });
      }, 2000);
    });
    socketService.emit(
      'join_unauthorized_call',
      {
        meetingId,
        userName,
        peerId: myCallData?.peerId,
      },
      ({ status, data }) => {},
    );

    socketService.on('leave_unauthorized_call', ({ meetingId, userName, peerId, reason }) => {
      setUsersOnCall((prevUsers) => {
        const userExists = prevUsers?.some((user) => user?.peerId == peerId);

        if (userExists) {
          const user = prevUsers?.filter((user) => user?.peerId == peerId)[0];
          setTimeout(() => {
            message.info(`${userName} left the call.`, [10]);
          }, 1000);

          return prevUsers?.filter((user) => user?.peerId != peerId);
        }
        return prevUsers;
      });
    });
    socketService.on('audioMode', ({ meetingId, userName, audioMode, peerId }) => {
      setUsersOnCall((prevUsers) => {
        return prevUsers?.map((user) => (user?.peerId == peerId ? { ...user, audioMode } : user));
      });
    });

    socketService.on('videoMode', ({ meetingId, userName, videoMode, peerId }) => {
      setUsersOnCall((prevUsers) => {
        return prevUsers?.map((user) => (user?.peerId == peerId ? { ...user, videoMode } : user));
      });
    });

    // socketService.emit('join room', meetingId, '<<>>>', socketService.id());
    // reduxDispatch(setMyCallData({ ...myCallData, peerId: socketService?.id() }));
    // socketService.on('all users', (users) => {
    //   const peers = [];

    //   users.forEach((userID) => {
    //     const peer = createPeer(userID, socketService.id(), localStream, userName);
    //     peersRef.current.push({
    //       peerID: userID,
    //       peer,
    //     });
    //     peers.push(peer);
    //   });

    //   // setUsersOnCall(peers);
    //   // setPeers(peers);
    // });

    // socketService.on('user joined', (payload) => {
    //   console.log('user joined', payload);
    //   const peer = addPeer(payload.signal, payload.callerID, localStream, userName);
    //   peersRef.current.push({
    //     peerID: payload.callerID,
    //     peer,
    //   });

    //   setUsersOnCall((users) => {
    //     return [
    //       ...users,
    //       {
    //         peer,
    //         userName: payload.userName,
    //         audioMode: payload?.audioMode,
    //         videoMode: payload?.videoMode,
    //         peerId: payload.callerID,
    //       },
    //     ];
    //   });
    //   setPeers((users) => [...users, peer]);
    // });

    // socketService.on('receiving returned signal', (payload) => {
    //   console.log('receiving return', payload);
    //   const item = peersRef.current.find((p) => p.peerID === payload.id);
    //   console.log('item here ==>', item);
    //   item.peer.signal(payload.signal);
    //   if (item?.peer) {
    //     setUsersOnCall((prevUsers) => {
    //       const userExists = prevUsers?.some((user) => user?.peer === item?.peer);
    //       if (!userExists) {
    //         return [
    //           ...prevUsers,
    //           {
    //             peer: item?.peer,
    //             userName: payload.userName,
    //             peerId: item?.peerID,
    //             audioMode: payload?.audioMode,
    //             videoMode: payload?.videoMode,
    //           },
    //         ];
    //       } else {
    //         return prevUsers;
    //       }
    //     });
    //   }
    // });
    socketService.emit('ready');
  };

  function createPeer(userToSignal, callerID, lStream, userName) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream: lStream,
      config: {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'turn:52.23.249.220:3478?transport=tcp', username: 'username1', credential: 'password1' },
        ],
      },
    });

    peer.on('signal', (signal) => {
      socketService.emit('sending signal', { userToSignal, callerID, signal, userName });
    });

    peer.on('stream', (stream) => {
      console.log('Stream is here on create peer', stream);
      updatePeerStream(peer, stream);
    });

    peer.on('close', () => {
      console.log('A peer is closed');
    });
    peer.on('error', (err) => {
      console.log('A peer has error', err);
    });

    peer.addListener('track', (str) => {
      console.log(' a track changed', str);
    });

    peer.addListener('stream', (str) => {
      console.log(' a stream changed', str);
    });

    return peer;
  }

  function addPeer(incomingSignal, callerID, lStream, userName) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream: lStream,
      config: {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'turn:52.23.249.220:3478?transport=tcp', username: 'username1', credential: 'password1' },
        ],
      },
    });

    peer.on('signal', (signal) => {
      socketService.emit('returning signal', { signal, callerID, userName });
    });

    peer.on('stream', (stream) => {
      console.log('Stream is here on add peer', stream);
      updatePeerStream(peer, stream);
    });

    peer.signal(incomingSignal);

    peer.on('close', () => {
      console.log('A peer is closed');
    });
    peer.on('error', (err) => {
      console.log('A peer has error', err);
    });

    peer.addListener('track', (str) => {
      console.log(' a track changed', str);
    });

    peer.addListener('stream', (str) => {
      console.log(' a stream changed', str);
    });

    return peer;
  }

  const updatePeerStream = (peer, stream) => {
    setUsersOnCall((prevUsers) => {
      const userExists = prevUsers?.some((user) => user?.peer === peer);
      if (!userExists) {
        return prevUsers;
      } else {
        return prevUsers?.map((user) => {
          if (user?.peer === peer) {
            return {
              ...user,
              stream,
              audioMode: stream?.getAudioTracks()[0]?.enabled,
              videoMode: stream?.getVideoTracks()[0]?.enabled,
            };
          }
          return user;
        });
      }
    });
  };

  return { handleJoinCall, peers };
};

export default useJoinCall;
