import {
  SET_AFFILIATE_STUDY,
  SET_AVAILABLE_STUDIES,
  SET_BACKLOGS,
  SET_BRANCH_CONTRACTS,
  SET_BRANCH_STUDIES,
  SET_CONTRACTS,
  SET_MY_STUDIES,
  SET_OFFERS,
  SET_SELECTED_ROWS,
  SET_SHARED,
  SET_STUDIES,
  SET_STUDY_ID,
  SET_STUDY_ID_ARRAY,
  SET_TOTAL_STUDIES,
  SET_VIEWER_PATIENT_STUDIES,
  SET_VIEWER_STUDY,
} from './types';

const initialState = {
  studies: [],
  myStudies: [],
  availableStudies: [],
  total: '',
  backlogs: [],
  contracts: [],
  shared: [],
  branchStudies: [],
  branchContracts: [],
  viewerPatientStudies: [],
  viewerStudy: {},
  affiliate: [],
  studyId: null,
  studyIdArray: [],
  selectedRows: [],
};

const studiesReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SET_STUDIES:
      return {
        ...state,
        studies: data,
      };
    case SET_MY_STUDIES:
      return {
        ...state,
        myStudies: data,
      };
    case SET_AVAILABLE_STUDIES:
      return {
        ...state,
        availableStudies: data,
      };
    case SET_BACKLOGS:
      return {
        ...state,
        backlogs: data,
      };
    case SET_TOTAL_STUDIES:
      return {
        ...state,
        total: data,
      };
    case SET_CONTRACTS:
      return {
        ...state,
        contracts: data,
      };
    case SET_SHARED:
      return {
        ...state,
        shared: data,
      };
    case SET_BRANCH_STUDIES:
      return {
        ...state,
        branchStudies: data,
      };
    case SET_BRANCH_CONTRACTS:
      return {
        ...state,
        branchContracts: data,
      };
    case SET_VIEWER_PATIENT_STUDIES:
      return {
        ...state,
        viewerPatientStudies: data,
      };
    case SET_VIEWER_STUDY:
      return {
        ...state,
        viewerStudy: data,
      };
    case SET_AFFILIATE_STUDY:
      return {
        ...state,
        affiliate: data,
      };
    case SET_STUDY_ID:
      return {
        ...state,
        studyId: data,
      };
    case SET_STUDY_ID_ARRAY:
      let idArray = [];

      if (Array.isArray(data)) {
        idArray = data;
      } else {
        idArray = [data];
      }

      return {
        ...state,
        studyIdArray: idArray,
      };

    case SET_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: data,
      };

    default:
      return state;
  }
};

export default studiesReducer;
