import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Tooltip } from 'antd';
import MyCallCard from '../MyCallCard';
import useJoinCall from './hooks/useJoinCall';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentMeetingId, setMyCallData, setOnCall } from '../../../../../redux/globals/actions';
import { IoCopy } from 'react-icons/io5';
import { copyText, createMeetingId } from '../../../../../utility/helpers';
import { useNavigate } from 'react-router-dom';
import { MdArrowLeft, MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { BsArrowBarLeft } from 'react-icons/bs';

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${name} is not a valid email!',
    number: '${name} is not a valid number!',
  },
  number: {
    range: '${name} must be between ${min} and ${max}',
  },
};

const JoinCall = ({
  setUsersOnCall,
  state,
  localStreamRef,
  getDeviceStream,
  toggleMic,
  toggleVideo,
  myScreenTrack,
  myVideoTrack,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [meetingId, setMeetingId] = useState('');
  const { myCallData, currentMeetingId } = useSelector((state) => state.globals);
  const [isNew, setIsNew] = useState(false);
  const { audioMode, videoMode, screenMode } = state;
  const reduxDispatch = useDispatch();
  const history = useNavigate();
  const { handleJoinCall } = useJoinCall({
    setUsersOnCall,
    audioMode,
    myScreenTrack,
    myVideoTrack,
    videoMode,
    localStreamRef,
    screenMode,
  });
  const navigate = useNavigate();
  const onFinish = () => {
    let mId;
    if (currentMeetingId && currentMeetingId?.trim()?.length) {
      mId = currentMeetingId.trim();
    } else {
      mId = meetingId.trim();
    }
    // navigate(`/meet/${mId}`, {
    //   state: { username },
    // });
    window.open(`https://meet.xolanibridge.com`);
    // reduxDispatch(setOnCall(true));
    // handleJoinCall(username, mId);
  };

  const domain = window.location.origin;
  //const callUrlLink = `${domain}/meet/${currentMeetingId}`;
  const callUrlLink = `https://meet.xolanibridge.com/${currentMeetingId}`;

  useEffect(() => {
    const getMeetingId = createMeetingId();
    if (isNew) {
      reduxDispatch(setCurrentMeetingId(`vd-${getMeetingId}`));

      form.setFieldValue('meetingId', `vd-${getMeetingId}`);
    } else {
      form.setFieldValue('meetingId', '');
    }
  }, [isNew]);
  const handleBack = () => {
    history('/');
    window.location.reload();
  };
  return (
    <div className="flex flex-col justify-center items-center h-full">
      <div
        style={{
          display: 'flex',
          marginBottom: 10,
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={handleBack}
      >
        <MdOutlineKeyboardArrowLeft size={30} /> Back to Home
      </div>
      <div className="bg-[#D6EBF1] p-8 rounded-xl  flex flex-wrap gap-6 justify-center">
        <MyCallCard
          localStreamRef={localStreamRef}
          user={{ name: username }}
          singleUser
          onJoin
          state={state}
          toggleMic={toggleMic}
          toggleVideo={toggleVideo}
          getDeviceStream={getDeviceStream}
        />

        <Form
          layout="vertical"
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
          form={form}
          style={{
            width: '360px',
            backgroundColor: '#fff',
          }}
          className="rounded-[15px] p-4 px-8 pt-2 sm:w-[80vw]"
        >
          <div className="flex items-center justify-center gap-4">
            <div
              className={`text-md px-4 border-1 border-gray-400 cursor-pointer  rounded-md  ${
                !isNew ? 'drop-shadow-lg bg-primary text-white' : 'hover:bg-gray-200'
              }`}
              onClick={() => setIsNew(false)}
            >
              Join
            </div>
            <div
              className={`text-md px-4 border-1 border-gray-400 cursor-pointer rounded-md  ${
                !!isNew ? 'drop-shadow-lg bg-primary text-white' : 'hover:bg-gray-200'
              }`}
              onClick={() => setIsNew(true)}
            >
              Start
            </div>
          </div>
          <div className="flex flex-row items-center justify-center font-normal text-xl py-3 mb-6 border-b-1">
            {!isNew ? 'Join' : 'Start New'} Meeting
          </div>

          {!!isNew && (
            <>
              <Row gutter={16} align="bottom">
                <Col span={24}>
                  <Form.Item
                    name="username"
                    label="Username"
                    className="mb-2 [&>div>div>label]:text-sm [&>div>div>label]:text-gray-500 dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      value={meetingId}
                      onChange={({ target }) => {
                        setUsername(target?.value);
                        reduxDispatch(setMyCallData({ ...myCallData, userName: target.value }));
                      }}
                      className="text-gray-500 text-sm"
                      placeholder="username"
                    />
                  </Form.Item>
                </Col>
                <Col span={20}>
                  <Form.Item
                    name="meetingId"
                    label="Meeting Code"
                    className="mb-0 [&>div>div>label]:text-sm [&>div>div>label]:text-gray-500 dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      value={meetingId}
                      onChange={({ target }) => setMeetingId(target?.value)}
                      className="text-gray-500 text-sm"
                      placeholder="vc-b6cd-b8df"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Tooltip
                    title={
                      <p className="bg-gray-200 p-1 flex align-middle items-center">
                        <strong>Code:&nbsp;</strong> {currentMeetingId}
                        <span
                          className="pl-2 cursor-pointer hover:scale-105"
                          onClick={() => copyText('Meeting code', callUrlLink)}
                        >
                          <IoCopy size={18} />
                        </span>
                      </p>
                    }
                  >
                    <Button className="flex flex-col w-10 h-10 mb-[5px] items-center align-middle justify-center rounded-full hover:scale-105 bg-gray-300 text-black sm:w-10 sm:h-10">
                      <IoCopy size={18} />
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
              <Row gutter={16} className="flex justify-center  pt-6 pb-3">
                <Button
                  className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-sm font-semibold leading-[22px] inline-flex items-center justify-center rounded-2xl px-[30px] py-[5px]"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={isLoading}
                >
                  {isLoading ? 'Creating...' : 'Create Meeting'}
                </Button>
              </Row>
            </>
          )}
          {!isNew && (
            <>
              <Row gutter={16} align="bottom">
                <Col span={24}>
                  <Form.Item
                    name="username"
                    label="Username"
                    className="mb-2 [&>div>div>label]:text-sm [&>div>div>label]:text-gray-500 dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      value={meetingId}
                      onChange={({ target }) => {
                        setUsername(target?.value);
                        reduxDispatch(setMyCallData({ ...myCallData, userName: target.value }));
                      }}
                      className="text-gray-500 text-sm"
                      placeholder="username"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="meetingId"
                    label="Meeting Code"
                    className="mb-0 [&>div>div>label]:text-sm [&>div>div>label]:text-gray-500 dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      value={meetingId}
                      onChange={({ target }) => setMeetingId(target?.value)}
                      className="text-gray-500 text-sm"
                      placeholder="vc-b6cd-b8df"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="flex justify-center  pt-6 pb-3">
                <Button
                  className="bg-primary hover:bg-white hover:text-primary border-solid border-1 border-primary text-white dark:text-white87 text-sm font-semibold leading-[22px] inline-flex items-center justify-center rounded-2xl px-[30px] py-[5px]"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={isLoading}
                >
                  {isLoading ? 'Joining...' : 'Join Meeting'}
                </Button>
              </Row>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

export default JoinCall;
